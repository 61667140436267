import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../../BrandSelectionForm/BrandSelectionForm";

const InventoryHealthForm = ({ brandName, setBrandName, loading }) => {
  return (
    <BrandSelectionForm
      brandName={brandName}
      setBrandName={setBrandName}
      loading={loading}
    />
  );
};

export default InventoryHealthForm;

import styles from "./Styles.module.css";
import { Button, Chip, Link, Paper, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useMemo, useRef } from "react";
import EditNoteTwoToneIcon from "@mui/icons-material/EditNoteTwoTone";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const getStringWidth = (text, font) => {
  // Create a temporary canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set font for context
  context.font = font;

  // Measure the width of the text
  const width = context.measureText(text).width;

  // Clean up
  canvas.remove();

  return width;
};

const ExpandableCell = ({ value }) => {
  const [expanded, setExpanded] = React.useState(false);
  const elementCount = React.Children.count(value);
  let width = 400;
  let minItems = 0;
  let totalWidth = 0;

  value.forEach((elem) => {
    let itemWidth = getStringWidth(elem, "12px");
    totalWidth += itemWidth;
    width = width - itemWidth;
    if (width > 0 && minItems < 5) {
      minItems++;
    }
  });

  return (
    <div>
      {expanded
        ? value.map((elem, index) => (
            <Chip className={styles.ucName} key={index + 1} label={elem} />
          ))
        : React.Children.toArray(value)
            .slice(0, minItems)
            .map((elem, index) => (
              <Chip className={styles.ucName} key={index + 1} label={elem} />
            ))}
      {/* Adjust the slice value */}
      {elementCount > minItems && (
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <span className={styles.viewButton}>view less</span>
          ) : (
            <span className={styles.viewButton}>view more</span>
          )}
        </Link>
      )}
    </div>
  );
};

const ChannelTable = ({
  channels,
  b2bTypes,
  ucChannels,
  setUpdateSelectedChannel,
  handleChUpdateFormOpen,
  loading,
}) => {
  const gridRef = useRef();

  const allChannels = channels.map((channel) => {
    let uc = ucChannels
      .filter((ucChannel) => {
        if (channel.id === ucChannel.channel) return ucChannel;
      })
      .map((ch) => ch.name);
    return { ...channel, uc };
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 175,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "b2b_type",
      headerName: "B2B Type",
      minWidth: 175,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "uc",
      headerName: "Unicommerce Channels",
      minWidth: 800,
      flex: 2,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => <ExpandableCell {...params} />,
    },
    {
      field: "Edit",
      headerName: "Edit",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (cellValues) => {
        return (
          <Button
            sx={{
              marginLeft: "auto",
              color: "black",
            }}
            onClick={(event) => {
              setUpdateSelectedChannel(
                channels.filter(
                  (channel) => channel.name === cellValues.data.name
                )[0].id
              );
              handleChUpdateFormOpen();
            }}
          >
            <EditNoteTwoToneIcon />
          </Button>
        );
      },
    },
  ];

  const rows = allChannels.map((channel, index) => {
    return {
      id: index + 1,
      name: channel.name,
      b2b_type: b2bTypes[channel.b2b_type][1],
      uc: channel.uc,
    };
  });

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Channels(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{
        height: rows.length < 12 ? "" : "700px",
        margin: "2em 0",
      }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 12 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          rowHeight={50}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
        />
      )}
    </Paper>
  );
};

export default ChannelTable;

import { Button, Box, Paper } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Styles.module.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SaveIcon from "@mui/icons-material/Save";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { toast } from "react-toastify";
import { classificationBulkUpdate } from "../../Utils/helperFunctions";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const classType = ["Hero", "Discontinued", "Others"];

const ClassificationTable = ({
  brandName,
  classifications,
  getClassification,
  loading,
}) => {
  const gridRef = useRef();
  let updatedClassificationDict = {};
  const rows = classifications.map((elem) => {
    return { ...elem, class_type: classType[elem.class_type] };
  });

  const [columns] = useState([
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 200,
      editable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "no_replenishment",
      headerName: "No Replenishment",
      flex: 1,
      minWidth: 200,
      editable: true,
      cellEditor: "agCheckboxCellEditor",
    },
    {
      field: "class_type",
      headerName: "Class Type",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: classType,
      },
    },
    {
      field: "doh_norm",
      headerName: "DOH Norm (Days)",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      editable: true,
    },
    {
      field: "product_count",
      headerName: "Product Count",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
    },
  ]);

  const onCellValueChanged = (row) => {
    const { product_count, ...restData } = row.data;
    updatedClassificationDict[row.data.id] = {
      ...restData,
      class_type: classType.indexOf(row.data.class_type),
    };
  };

  const handleSave = async () => {
    if (Object.keys(updatedClassificationDict).length) {
      const postData = {
        brand: brandName,
        classifications: Object.values(updatedClassificationDict),
      };
      try {
        const response = await classificationBulkUpdate(postData);
        if (response.status === 200) {
          toast.success("Classifictions updated successfully");
          getClassification();
          updatedClassificationDict = {};
        }
      } catch (error) {
        toast.error("Error while classification bulk update!");
        console.log("Error while classification bulk update: ", error);
      }
    } else {
      toast.info("Nothing to update!");
    }
  };

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Classification(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{
        height: !loading && rows.length > 13 ? "700px" : "",
      }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button onClick={onBtnExport} startIcon={<SaveAltIcon />}>
          Export
        </Button>
        <Button onClick={handleSave} startIcon={<SaveIcon />}>
          Save
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ height: "300px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          domLayout={rows.length <= 13 ? "autoHeight" : null}
          rowData={rows}
          rowHeight={45}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          onCellValueChanged={onCellValueChanged}
        />
      )}
    </Paper>
  );
};

export default ClassificationTable;

import React, { useEffect, useState } from "react";
import styles from "./InventoryHealth.module.css";
import InventoryHealthForm from "../../../Components/Reports/InventoryHealth/InventoryHealthForm";
import InventoryHealthTable from "../../../Components/Reports/InventoryHealth/InventoryHealthTable";
import { fetchInventoryHealthReports } from "../../../Utils/helperFunctions";
import { toast } from "react-toastify";

const InventoryHealth = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [inventoryHealthReports, setInventoryHealthReports] = useState({});

  const getInventoryHealthReports = async () => {
    setLoading(true);
    setInventoryHealthReports({});
    const postData = { brand: brandName, classification: [] };
    try {
      const response = await fetchInventoryHealthReports(postData);
      if (response.status === 200) {
        setInventoryHealthReports(response.data.inventory_health);
        setLoading(false);
      }
    } catch (error) {
      toast("Error while get inventory health reports");
      console.log("Error while get inventory health reports: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brandName) {
      getInventoryHealthReports();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Inventory Health</h2>
      <InventoryHealthForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <InventoryHealthTable
        reports={inventoryHealthReports}
        loading={loading}
      />
    </div>
  );
};

export default InventoryHealth;

import React, { useEffect, useState } from "react";
import styles from "./DailySalesInventory.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DailySalesForm from "../../Components/DailySalesInventory/DailySalesForm";
import DailySalesTable from "../../Components/DailySalesInventory/DailySalesTable";
import DailySalesGraph from "../../Components/DailySalesInventory/DailySalesGraph";
import { dateFormat, fetchDailySales } from "../../Utils/helperFunctions";
import { Modal, Box, Grid, IconButton } from "@mui/material";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DailySalesInventory = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  let dateStart = new Date();
  let dateEnd = new Date();
  dateStart.setDate(1);
  dateEnd.setDate(1);
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateEnd.setMonth(dateEnd.getMonth() + 1);
  dateEnd = new Date(dateEnd.getFullYear(), dateEnd.getMonth() + 1, 0);
  dateStart = dateFormat(dateStart);
  dateEnd = dateFormat(dateEnd);
  const [dateRange, setDateRange] = useState(`${dateStart}~${dateEnd}`);
  const [products, setProducts] = useState([]);
  const [months, setMonths] = useState([]);
  const [forecastMonths, setForecastMonths] = useState([]);
  const [open, setOpen] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDailySales = async () => {
    setLoading(true);
    setProducts([]);
    let [firstDate, lastDate] = dateRange.split("~");
    const postData = {
      brand: brandName,
      date_start: firstDate,
      date_end: lastDate,
    };
    try {
      const response = await fetchDailySales(postData);
      if (response.status === 200) {
        setProducts(response.data.products);
        setMonths(response.data.months);
        setForecastMonths(
          response.data.forecast_months.map((month) => month + "_f")
        );
        setLoading(false);
      }
    } catch (error) {
      toast("Error while get daily sales!");
      console.log("Error while get daily sales: ", error);
      setProducts(false);
    }
  };

  useEffect(() => {
    if (brandName) {
      getDailySales();
    }
  }, [brandName, dateRange]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Daily Sales</h2>
      <DailySalesForm
        brandName={brandName}
        setBrandName={setBrandName}
        dateRange={dateRange}
        setDateRange={setDateRange}
        loading={loading}
      />
      <DailySalesTable
        products={products}
        months={months}
        forecastMonths={forecastMonths}
        brandName={brandName}
        dateRange={dateRange}
        setInventoryData={setInventoryData}
        handleOpen={handleOpen}
        loading={loading}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <IconButton
                onClick={handleClose}
                variant="contained"
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <DailySalesGraph inventoryData={inventoryData} />
        </Box>
      </Modal>
    </div>
  );
};

export default DailySalesInventory;

import { Alert, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./Styles.module.css";

const ConfirmationModal = ({
  openConfirmationModal,
  handleCloseConfirmationModal,
  updateForAllProducts,
}) => {
  return (
    <Box>
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={updateForAllProducts}>
          <Box className={styles.confirmationModal}>
            <Alert severity="info" sx={{ fontWeight: "bold", color: "grey" }}>
              Please note: This action will permanently change values for all products. Fields left empty will not be affected by this update.
            </Alert>
            <Box className={styles.buttonBox}>
              <button
                className={styles.submitButton}
                onClick={handleCloseConfirmationModal}
              >
                CANCEL
              </button>
              <button type="submit" className={styles.submitButton}>
                UPDATE
              </button>
            </Box>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default ConfirmationModal;

import React, { useEffect, useState } from "react";
import styles from "./PlannedVsActual.module.css";
import PlannedVsActualForm from "../../../Components/Reports/PlannedVsActual/PlannedVsActualForm";
import PlannedVsActualTable from "../../../Components/Reports/PlannedVsActual/PlannedVsActualTable";
import {
  fetchClassification,
  fetchReportsDemand,
} from "../../../Utils/helperFunctions";
import { toast } from "react-toastify";

const PlannedVsActual = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [reportsDemand, setReportsDemand] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const currentYear = new Date().getFullYear();
  const currnetMonth = new Date().getMonth();
  const [fy, setFy] = useState(
    currnetMonth > 2
      ? currentYear + `-${currentYear + 1}`
      : currentYear - 1 + `${currentYear}`
  );

  const financialYears =
    currnetMonth > 2
      ? [
          currentYear + 2 + `-${currentYear + 3}`,
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
        ]
      : [
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
          currentYear - 3 + `-${currentYear - 2}`,
        ];

  const getReportsDemand = async () => {
    setLoading(true);
    setReportsDemand([]);
    const postData = {
      brand: brandName,
      financial_year: fy,
      classification: selectedClassifications.map(
        (classification) => classification.value
      ),
    };
    try {
      const response = await fetchReportsDemand(postData);
      if (response.status === 200 && response.data) {
        setReportsDemand(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      toast("Error while get planned vs actual data!");
      console.log("Error while get reports demand: ", error);
      setLoading(false);
    }
  };

  const getClassification = async () => {
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(
        response.data.classifications.map((value) => {
          return { value: value.title, label: value.title };
        })
      );
    } catch (error) {
      console.log("Error while get classification: ", error);
    }
  };

  const handleSelectClassification = (selectedValue) => {
    setSelectedClassifications(selectedValue);
  };

  useEffect(() => {
    if (brandName) {
      getClassification();
    }
  }, [brandName]);

  useEffect(() => {
    if (brandName) {
      getReportsDemand();
    }
  }, [brandName, fy, selectedClassifications]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Planned vs Actual</h2>
      <PlannedVsActualForm
        brandName={brandName}
        setBrandName={setBrandName}
        financialYears={financialYears}
        fy={fy}
        setFy={setFy}
        classifications={classifications}
        selectedClassifications={selectedClassifications}
        handleSelectClassification={handleSelectClassification}
        loading={loading}
      />
      <PlannedVsActualTable reports={reportsDemand} loading={loading} />
    </div>
  );
};

export default PlannedVsActual;

import ReactEcharts from "echarts-for-react";

const InventoryCapitalGraph = ({ workingCapitalData }) => {
  const option = {
    title: { text: "Daily Inventory Capital" },
    legend: {
      data: ["Inventory Capital"],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: function (params) {
        const date = params[0].axisValue;
        const value = params[0].value;
        return `${date}<br/>${params[0].seriesName}: ₹${Math.round(
          value
        ).toLocaleString()}`;
      },
    },
    xAxis: {
      type: "category",
      data: workingCapitalData.map((data) => data.date),
      name: "Date (YYYY-MM-DD)",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 20,
      },
    },
    yAxis: {
      type: "value",
      name: "Inventory Capital (₹)",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 70,
      },
    },
    series: [
      {
        name: "Inventory Capital",
        data: workingCapitalData.map((data) => data.working_capital),
        type: "line",
        lineStyle: {
          color: "blue",
        },
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default InventoryCapitalGraph;

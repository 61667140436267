import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "25px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "25px",
    margin: "auto",
  }),
};

const DrrUpdateForm = ({
  brandName,
  setBrandName,
  loading,
  channels,
  selectedChannel,
  setSelectedChannel,
}) => {
  return (
    <div className={styles.formBox}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox} style={{ marginLeft: "2em" }}>
        <label className={styles.label}>Channels</label>
        <Select
          className={styles.selectInput}
          styles={customStyles}
          placeholder="Select channel"
          value={selectedChannel}
          options={channels}
          onChange={(channel) => {
            setSelectedChannel(channel);
          }}
          isDisabled={loading}
        />
      </div>
    </div>
  );
};

export default DrrUpdateForm;

import styles from "./BrandConfiguration.module.css";
import BrandConfigurationForm from "../../Components/BrandConfiguration/BrandConfigurationForm";
import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import InventoryNormTable from "../../Components/BrandConfiguration/InventoryNormTable";
import { toast } from "react-toastify";
import { fetchInventoryNorm } from "../../Utils/helperFunctions";

const BrandConfiguration = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [frequency, setFrequency] = useState("M");
  const [occurenceCount, setOccurenceCount] = useState(1);
  const [firstOcc, setFirstOcc] = useState();
  const [secondOcc, setSecondOcc] = useState();
  const [thirdOcc, setThirdOcc] = useState();
  const [inventoryNorm, setInventoryNorm] = useState([]);
  const currentYear = new Date().getFullYear();
  const currnetMonth = new Date().getMonth();
  const currentFinancialYear = brand
    ? currnetMonth > 2
      ? brand.year
      : brand.year - 1
    : currnetMonth > 2
    ? currentYear
    : currentYear - 1;

  const [fy, setFy] = useState(
    currnetMonth > 2
      ? currentFinancialYear + `-${currentFinancialYear + 1}`
      : currentFinancialYear - 1 + `${currentFinancialYear}`
  );

  const years =
    currnetMonth > 2
      ? [
          currentYear + 2 + `-${currentYear + 3}`,
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
        ]
      : [
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
          currentYear - 3 + `-${currentYear - 2}`,
        ];

  const getInventoryNorm = async () => {
    setLoading(true);
    const postData = { brand: brandName, financial_year: fy };
    try {
      const response = await fetchInventoryNorm(postData);
      if (response.status === 200) {
        setInventoryNorm(response.data.inventory_norm);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while get inventory norm!");
      console.log("Error while get inventory norm: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brandName) {
      getInventoryNorm();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Brand Configuration</h2>
      <BrandConfigurationForm
        brand={brand}
        brandName={brandName}
        setBrandName={setBrandName}
        frequency={frequency}
        setFrequency={setFrequency}
        occurenceCount={occurenceCount}
        setOccurenceCount={setOccurenceCount}
        firstOcc={firstOcc}
        setFirstOcc={setFirstOcc}
        secondOcc={secondOcc}
        setSecondOcc={setSecondOcc}
        thirdOcc={thirdOcc}
        setThirdOcc={setThirdOcc}
      />
      <Paper sx={{ margin: "2em 0" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            color: "grey",
            height: "15px",
          }}
        >
          Inventory Norm Table
        </Typography>
        <hr />
        <InventoryNormTable
          brandName={brandName}
          inventoryNorm={inventoryNorm}
          loading={loading}
          fy={fy}
          setFy={setFy}
          years={years}
          getInventoryNorm={getInventoryNorm}
        />
      </Paper>
    </div>
  );
};

export default BrandConfiguration;

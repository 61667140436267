import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import styles from "./Styles.module.css";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ChannelCreateForm = ({
  b2bTypes,
  ucChannels,
  createB2bType,
  setCreateB2bType,
  createSelectedUcChannels,
  setCreateSelectedUcChannels,
  createChannelName,
  setCreateChannelName,
  createNewChannel,
}) => {
  const handleUcSlectionChange = (event, options) => {
    setCreateSelectedUcChannels(options);
  };

  return (
    <form className={styles.channleCreateForm} onSubmit={createNewChannel}>
      <Box>
        <TextField
          id="outlined-basic"
          label="Channel Name"
          variant="outlined"
          type="text"
          className={styles.createChannelFormInput}
          value={createChannelName}
          onChange={(e) => setCreateChannelName(e.target.value)}
          sx={{ fontSize: "5px" }}
          required
        />
      </Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select B2B Type</InputLabel>
        <Select
          className={styles.createChannelFormInput}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={createB2bType}
          label="Select B2B Type"
          onChange={(e) => setCreateB2bType(e.target.value)}
        >
          {b2bTypes.map((elem) => {
            return (
              <MenuItem
                key={elem[0]}
                value={elem[0]}
                className={styles.dropdownOptions}
              >
                {elem[1]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          value={createSelectedUcChannels}
          options={ucChannels.map((ucChannel) => ucChannel.name)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          onChange={(event, options) => handleUcSlectionChange(event, options)}
          sx={{ maxHeight: 150, overflow: "scroll", paddingTop: 1 }}
          ListboxProps={{ style: { maxHeight: "15rem" } }}
          renderOption={(props, option, { selected }) => (
            <li {...props} className={styles.dropdownOptions}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}{" "}
              <span className={styles.channelName}>
                (
                {
                  ucChannels.filter((ucChannel) => ucChannel.name === option)[0]
                    .channel__name
                }
                )
              </span>
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Unicommerce Channel" />
          )}
        />
      </FormControl>
      <input
        className={styles.createChannelButton}
        type="submit"
        value={"CREATE CHANNEL"}
      />
    </form>
  );
};

export default ChannelCreateForm;

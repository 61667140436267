import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Styles.module.css";
import {
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import SearchIcon from "@mui/icons-material/Search";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const Row = ({ row, searchKeyword, loading }) => {
  const [open, setOpen] = useState(false);
  const gridRef = useRef();
  const totalRowDict = { quantity: 0, price: 0 };

  const columns = [
    {
      field: "purchase_order_code",
      headerName: "Purchase Order Code",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "delivery_date",
      headerName: "Delivery Date",
      flex: 1,
      minWidth: 160,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "price",
      headerName: "₹ Price",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
      valueFormatter: (params) => `₹${params.value.toLocaleString()}`,
    },
  ];

  const rows = row.orders.map((order, index) => {
    totalRowDict["quantity"] += order.expected_quantity;
    totalRowDict["price"] += order.total_price;
    return {
      id: index + 1,
      purchase_order_code: order.purchase_order_code,
      sku: order.product__sku,
      name: order.product__name,
      quantity: order.expected_quantity,
      created_date: order.created_time.slice(0, 10),
      delivery_date: order.delivery_date,
      status: order.status,
      price: order.total_price,
    };
  });

  const totalRow = [
    {
      id: "total",
      purchase_order_code: "Total",
      quantity: totalRowDict["quantity"],
      price: Math.round(totalRowDict["price"]),
    },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Purchase Orders(${brand.name}) Aging(${
        row.aging
      }) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  useEffect(() => {
    if (row.orders.length && searchKeyword.length > 2) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [row, searchKeyword]);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        className={styles.tableRow}
      >
        <TableCell className={styles.tableCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            className={styles.iconButton}
          >
            {open ? (
              <KeyboardArrowUpIcon className={styles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ width: "32%" }}
          align="center"
        >
          {row["aging"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ width: "32%" }}
          align="center"
        >
          {totalRowDict["quantity"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ width: "32%" }}
          align="center"
        >
          ₹{Math.round(totalRowDict["price"]).toLocaleString()}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper
              sx={{
                height: rows.length < 10 ? "auto" : "450px",
                margin: rows.length < 10 ? "1em" : "1em 1em 4.5em 1em",
                borderRadius: "5px",
              }}
              className={`ag-theme-quartz ${styles.tableContainer}`}
            >
              <Box className={styles.customTableHeader}>
                <Button
                  className="exportButton"
                  onClick={onBtnExport}
                  startIcon={<SaveAltIcon />}
                >
                  Export
                </Button>
                <TextField
                  type="text"
                  size="small"
                  label="Search"
                  id="filter-text-box"
                  className={styles.searchField}
                  onInput={onFilterTextBoxChanged}
                />
              </Box>
              {loading ? (
                <Box sx={{ height: "450px" }} className="ag-theme-quartz">
                  <AgGridReact columnDefs={columns} rowData={null} />
                </Box>
              ) : (
                <AgGridReact
                  domLayout={rows.length < 10 ? "autoHeight" : null}
                  ref={gridRef}
                  columnDefs={columns}
                  rowData={rows}
                  pinnedBottomRowData={rows.length && totalRow}
                  rowHeight={35}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  enableCellTextSelection={true}
                  suppressMovableColumns={true}
                  getRowStyle={(params) => {
                    if (params.data.id === "total") {
                      return {
                        background: "#f2f1ed",
                        fontSize: "15px",
                        fontWeight: "bold",
                      };
                    }
                    return null;
                  }}
                />
              )}
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const PurchaseOrdersTabel = ({ orders, loading }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [purchaseOrders, setPurchaseOrders] = useState([]);

  const formatPurchaseOrders = (purchaseOrdersData) => {
    const purchaseOrdersObj = {
      "90+": [],
      "60-90": [],
      "30-60": [],
      "0-30": [],
    };
    const purchaseOrderList = [];

    purchaseOrdersData.forEach((order) => {
      switch (order.aging) {
        case "90+":
          purchaseOrdersObj["90+"].push(order);
          break;
        case "60-90":
          purchaseOrdersObj["60-90"].push(order);
          break;
        case "30-60":
          purchaseOrdersObj["30-60"].push(order);
          break;
        case "0-30":
          purchaseOrdersObj["0-30"].push(order);
          break;
        default:
          break;
      }
    });

    Object.entries(purchaseOrdersObj).forEach(([key, value]) => {
      purchaseOrderList.push({ aging: key, orders: value });
    });

    setPurchaseOrders(purchaseOrderList);
  };

  const convertArrayToCSV = (orders) => {
    const headers = Object.keys(orders[0]).join(","); // Get headers from the first object
    const rows = orders.map((obj) => Object.values(obj).join(",")).join("\n"); // Convert each object to a string of values
    return `${headers}\n${rows}`;
  };

  const downloadOdersAsCsv = () => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const csv = convertArrayToCSV([
      ...orders.map((order) => {
        return {
          purchase_order_code: order.purchase_order_code,
          sku: order.product__sku,
          name: order.product__name.replaceAll(",", " |"),
          quantity: order.expected_quantity,
          created_date: order.created_time.slice(0, 10),
          delivery_date: order.delivery_date,
          status: order.status,
          aging: order.aging,
          price: order.total_price,
        };
      }),
      {
        purchase_order_code: "Total",
        sku: "",
        name: "",
        quantity: purchaseOrders.reduce(
          (acc, curVal) =>
            acc +
            curVal.orders.reduce(
              (acc1, curVal1) => acc1 + curVal1.expected_quantity,
              0
            ),
          0
        ),
        created_date: "",
        delivery_date: "",
        status: "",
        aging: "",
        price: Math.round(
          purchaseOrders.reduce(
            (acc, curVal) =>
              acc +
              curVal.orders.reduce(
                (acc1, curVal1) => acc1 + curVal1.total_price,
                0
              ),
            0
          )
        ),
      },
    ]);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Purchase Orders(${
      brand.name
    }) ${new Date().toLocaleString()}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const searchOrders = (keyword, dataList) => {
    const lowerKeyword = keyword.toLowerCase();

    return dataList.filter((item) => {
      return Object.values(item).some(
        (value) =>
          value && value.toString().toLowerCase().includes(lowerKeyword)
      );
    });
  };

  const handleSearchOrders = () => {
    const result = searchOrders(searchKeyword, orders);
    formatPurchaseOrders(result);
  };

  useEffect(() => {
    if (searchKeyword.length > 2) {
      handleSearchOrders();
    } else {
      formatPurchaseOrders(orders);
    }
  }, [searchKeyword, orders]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "750px",
        marginTop: "2em",
        marginBottom: "3em",
      }}
    >
      <Box className={styles.featureRow}>
        <Button
          className={styles.exportButton}
          onClick={downloadOdersAsCsv}
          disabled={loading}
        >
          <SaveAltSharpIcon
            className={styles.downloadIcon}
            sx={{ fontSize: "18px !important" }}
          />
          <Typography sx={{ fontSize: "14px !important" }}> Export</Typography>
        </Button>
        <TextField
          className={styles.searchField}
          size="small"
          placeholder="Search..."
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          disabled={loading}
        />
      </Box>
      <Table
        stickyHeader
        aria-label="collapsible table"
        sx={{ maxHeight: "750px" }}
      >
        <TableHead>
          <TableRow className={styles.tableRowHead}>
            <TableCell className={styles.tableCellHead} />
            <TableCell className={styles.tableCellHead} align="center">
              Aging
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              Quantity
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              ₹ Price
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            {new Array(4).fill(1).map((row, index) => {
              return (
                <TableRow key={row + index}>
                  {new Array(4).fill(1).map((column, index) => {
                    return (
                      <TableCell
                        key={column + index}
                        className={styles.tableCell}
                      >
                        <Box>
                          <Skeleton />
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        ) : purchaseOrders.length === 0 ? (
          <TableBody sx={{ position: "relative", top: "50%", left: "700%" }}>
            <TableRow>
              <TableCell>No rows</TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {purchaseOrders.map((row) => (
              <Row
                key={row.aging}
                row={row}
                searchKeyword={searchKeyword}
                loading={loading}
              />
            ))}
            <TableRow className={styles.footerRow}>
              <TableCell></TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              >
                Total
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              >
                {purchaseOrders.reduce(
                  (acc, curVal) =>
                    acc +
                    curVal.orders.reduce(
                      (acc1, curVal1) => acc1 + curVal1.expected_quantity,
                      0
                    ),
                  0
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "16px" }}
              >
                ₹
                {Math.round(
                  purchaseOrders.reduce(
                    (acc, curVal) =>
                      acc +
                      curVal.orders.reduce(
                        (acc1, curVal1) => acc1 + curVal1.total_price,
                        0
                      ),
                    0
                  )
                ).toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default PurchaseOrdersTabel;

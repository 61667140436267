import { useCallback, useMemo, useRef } from "react";
import styles from "./Styles.module.css";
import { fetchSalesInventoryDetails } from "../../Utils/helperFunctions";
import { toast } from "react-toastify";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { Button, Paper, TextField, Box } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const DailySalesTable = ({
  products,
  months,
  forecastMonths,
  brandName,
  dateRange,
  setInventoryData,
  handleOpen,
  loading,
}) => {
  const gridRef = useRef();
  const [firstDate, lastDate] = dateRange.split("~");

  const totalRowsDict = {
    units_sold: 0,
    drr: 0,
    forecast: 0,
    forecast_drr: 0,
    ...months.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {}),
    ...forecastMonths.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {}),
  };

  const rows = products.map((product, index) => {
    totalRowsDict["drr"] += Math.round(product.actual_average * 100) / 100;
    totalRowsDict["forecast_drr"] +=
      Math.round(product.forecasted_average * 100) / 100;

    const monthData = months.reduce((acc, month) => {
      acc[month] = product.total[month];
      totalRowsDict[month] += product.total[month] ? product.total[month] : 0;
      return acc;
    }, {});

    const forecastMonthData = forecastMonths.reduce((acc, month) => {
      acc[month] = product.forecasted_total[month.slice(0, -2)];
      totalRowsDict[month] += product.forecasted_total[month.slice(0, -2)]
        ? product.forecasted_total[month.slice(0, -2)]
        : 0;
      return acc;
    }, {});

    return {
      id: index + 1,
      sku: product.product__sku,
      name: product.product__name,
      ...monthData,
      ...forecastMonthData,
      drr: Math.round(product.actual_average * 100) / 100,
      forecast: product.forecasted_total,
      forecast_drr: Math.round(product.forecasted_average * 100) / 100,
    };
  });

  const totalRow = [{ id: "total", sku: "Total", ...totalRowsDict }];

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "units_sold",
      headerName: "GROSS UNITS SOLD",
      flex: 1,
      minWidth: 200,
      children: months.map((month) => {
        return {
          field: month,
          headerName: `${month}`.toUpperCase(),
          flex: 1,
          minWidth: 120,
          cellStyle: { textAlign: "center" },
        };
      }),
    },
    {
      field: "drr",
      headerName: "PAST DRR",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "forecast",
      headerName: "GROSS UNITS FORECAST",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      children: forecastMonths.map((month) => {
        return {
          field: month,
          headerName: `${month}`.toUpperCase().slice(0, -2),
          flex: 1,
          minWidth: 120,
          cellStyle: { textAlign: "center" },
          cellRenderer: (params) => {
            if (params.value) return params.data[month].toFixed(0);
            return null;
          },
        };
      }),
    },
    {
      field: "forecast_drr",
      headerName: "FORECAST DRR",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value.toFixed(2);
      },
    },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Daily Sales(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  const getSalesInventoryDetails = async (sku) => {
    const postData = {
      brand: brandName,
      sku,
      date_start: firstDate,
      date_end: lastDate,
    };
    try {
      const response = await fetchSalesInventoryDetails(postData);
      setInventoryData(response.data.daily_sales);
    } catch (error) {
      toast.error("Error while get sales inventory details!");
      console.log("Error while get sales inventory details: ", error);
    }
  };

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} headerHeight={30} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          rowHeight={35}
          pinnedBottomRowData={rows.length && totalRow}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          headerHeight={30}
          onRowClicked={(params) => {
            if (params.data.id !== "total") {
              handleOpen();
              getSalesInventoryDetails(params.data.sku);
            }
          }}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
    </Paper>
  );
};

export default DailySalesTable;

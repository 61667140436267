import { useState } from "react";
import styles from "./Styles.module.css";
import Select from "react-select";
import { DateRangePicker } from "rsuite";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "25px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "25px",
    margin: "auto",
  }),
};

const InventoryCapitalForm = ({
  formSubmit,
  brandData,
  selectedBrands,
  handleSelectedBrandChange,
  dateRange,
  setDateRange,
  loading,
}) => {
  const [firstDate, lastDate] = dateRange.split("~");

  const [selectionRange, setSelectionRange] = useState([
    new Date(firstDate),
    new Date(lastDate),
  ]);

  const handleDateRangeSelection = (ranges) => {
    if (ranges) {
      let startDate = new Date(ranges[0]).toISOString().slice(0, 10);
      let endDate = new Date(ranges[1]).toISOString().slice(0, 10);
      setSelectionRange(ranges);
      setDateRange(`${startDate}~${endDate}`);
    } else {
      setSelectionRange(ranges);
    }
  };

  return (
    <form className={styles.form} onSubmit={formSubmit}>
      <div className={styles.inputBox}>
        <label className={styles.label}>Brand</label>
        <Select
          styles={customStyles}
          placeholder="Select Brands"
          closeMenuOnSelect={false}
          isMulti
          maxMenuHeight={170}
          options={brandData}
          value={selectedBrands}
          onChange={handleSelectedBrandChange}
          className={styles.selectInput}
          isDisabled={loading}
        />
      </div>
      <div className={styles.inputBox}>
        <label className={styles.label}>Date Range</label>
        <DateRangePicker
          className={styles.dateRangePicker}
          size="sm"
          onChange={handleDateRangeSelection}
          value={selectionRange}
          disabled={loading}
        />
      </div>
      <div>
        <input
          className={styles.submitButton}
          type="submit"
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default InventoryCapitalForm;

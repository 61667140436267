import React, { useEffect, useState } from "react";
import styles from "./DrrUpdate.module.css";
import DrrUpdateForm from "../../Components/DrrUpdate/DrrUpdateForm";
import DrrUpdateTable from "../../Components/DrrUpdate/DrrUpdateTable";
import {
  fetchChannels,
  fetchDailySalesForecastDrr,
  updateSalesForecastDrr,
} from "../../Utils/helperFunctions";
import { toast } from "react-toastify";

const DrrUpdate = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [salesForecast, setSalesForecast] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [updatedSalesForecast, setUpdatedSalesForecast] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState(null);

  const getChannels = async () => {
    setLoading(true);
    const postData = { brand: brandName };
    try {
      const response = await fetchChannels(postData);
      if (response.status === 200) {
        setChannels(
          response.data.channels.map((channel) => {
            return { value: channel.name, label: channel.name, id: channel.id };
          })
        );
        if (response.data.channels.length) {
          setSelectedChannel({
            value: response.data.channels[0].name,
            label: response.data.channels[0].name,
            id: response.data.channels[0].id,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("Unable to get channels for the brand", error);
      setLoading(false);
    }
  };

  const getDailySalesForecastDrr = async () => {
    setLoading(true);
    setSalesForecast([]);
    setDateList([]);
    const postdata = {
      brand: brandName,
      channel_id: selectedChannel.id,
    };
    try {
      const response = await fetchDailySalesForecastDrr(postdata);
      if (response.status === 200) {
        setSalesForecast(response.data.forecast_drr);
        setDateList(response.data.dates);
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while get daily sales forecast drr", error);
      setLoading(false);
    }
  };

  const updateSalesForecast = (gridApi, updateForMonth) => {
    const rowNode = gridApi.getRowNode(rowData.node.id);

    const sku = rowData.data.sku;
    const date = rowData.colDef.field;
    const value = rowData.newValue;
    let forecast = rowData.oldValue[1];

    const listOfDates = [];

    if (updateForMonth) {
      let currentDate = new Date(date);

      let year = currentDate.getFullYear();
      let month = currentDate.getMonth();

      let lastDay = new Date(year, month + 1, 0).getDate();

      for (let day = currentDate.getDate(); day <= lastDay; day++) {
        let currentDate = new Date(year, month, day);
        let formattedDate = `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
        listOfDates.push(formattedDate);
      }
    } else {
      listOfDates.push(date);
    }

    let tempData = updatedSalesForecast;

    listOfDates.forEach((d, i) => {
      rowNode.data[d] = [value, i === 0 ? forecast : rowData.data[d][1], true];

      const skuIndex = tempData.findIndex((item) => item.sku === sku);
      if (skuIndex !== -1) {
        const skuItem = tempData[skuIndex];
        const dateIndex = skuItem.dates.findIndex((item) => item.date === d);
        if (dateIndex !== -1) {
          skuItem.dates[dateIndex].quantity = value;
        } else {
          skuItem.dates.push({ date: d, quantity: value });
        }
      } else {
        tempData.push({ sku, dates: [{ date: d, quantity: value }] });
      }
      gridApi.refreshCells({ rowNodes: [rowNode] });
    });

    setUpdatedSalesForecast(tempData);
    setRowData(null);
  };

  const saveUpdatedSalesForecast = async () => {
    if (!updatedSalesForecast.length) {
      toast.info("There is nothing to update!");
      return;
    }
    setLoading(true);
    const postData = {
      brand: brandName,
      channel_id: selectedChannel.id,
      update_values: updatedSalesForecast,
    };
    try {
      const response = await updateSalesForecastDrr(postData);
      if (response.data.message === "Updated" && response.status === 200) {
        toast.success("DRR updated successfully");
        setUpdatedSalesForecast([]);
        getDailySalesForecastDrr();
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while update sales forecast drr!", error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error while updating the drr!"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getChannels();
  }, [brandName]);

  useEffect(() => {
    if (brandName && selectedChannel.id) {
      getDailySalesForecastDrr();
    }
  }, [brandName, selectedChannel]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>DRR Update</h2>
      <DrrUpdateForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
        channels={channels}
        selectedChannel={selectedChannel}
        setSelectedChannel={setSelectedChannel}
      />
      <DrrUpdateTable
        dateList={dateList}
        salesForecast={salesForecast}
        loading={loading}
        updateSalesForecast={updateSalesForecast}
        saveUpdatedSalesForecast={saveUpdatedSalesForecast}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setRowData={setRowData}
      />
    </div>
  );
};

export default DrrUpdate;

import { useEffect, useRef, useState } from "react";
import styles from "./DailyInventoryLevels.module.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchClassification,
  fetchInventory,
  fetchInventoryDetails,
} from "../../Utils/helperFunctions";
import { Box, Grid, IconButton, Modal, Paper } from "@mui/material";
import DailyInventoryTable from "../../Components/DailyInventoryLevels/DailyInventoryTable";
import DailyInventoryForm from "../../Components/DailyInventoryLevels/DailyInventoryForm";
import DailyInventoryGraph from "../../Components/DailyInventoryLevels/DailyInventoryGraph";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const DailyInventoryLevels = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [classifications, setClassifications] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [products, setProducts] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showB2C, setShowB2C] = useState(false);
  const [showB2B, setShowB2B] = useState(false);
  const isBrandChange = useRef(false);

  const handleB2CToggle = () => {
    setShowB2C(!showB2C);
  };

  const handleB2BToggle = () => {
    setShowB2B(!showB2B);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getInventory = async (brandChange = false) => {
    setLoading(true);
    setProducts([]);
    const postData = {
      brand: brandName,
      classification: brandChange
        ? []
        : selectedClassifications.map((classification) => classification.value),
    };
    try {
      const response = await fetchInventory(postData);
      if (response.status === 200) {
        setProducts(response.data.products);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while get inventory!");
      console.log("Error while get inventory: ", error);
      setLoading(false);
    }
    isBrandChange.current = false;
  };

  const getInventoryDetails = async (sku) => {
    const postData = {
      brand: brandName,
      sku,
    };
    try {
      const response = await fetchInventoryDetails(postData);
      if (response.status === 200) {
        setInventoryData(response.data.inventory_details);
      }
    } catch (error) {
      toast.error("Error while get inventory details");
      console.log("Error while get inventory: ", error);
    }
  };

  const getClassification = async () => {
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(
        response.data.classifications.map((value) => {
          return { value: value.title, label: value.title };
        })
      );
    } catch (error) {
      console.log("Error while get classification: ", error);
    }
  };

  const handleSelectClassification = (selectedValue) => {
    setSelectedClassifications(selectedValue);
  };

  useEffect(() => {
    isBrandChange.current = true;
    setClassifications([]);
    if (brandName) {
      getClassification();
    }
  }, [brandName]);

  useEffect(() => {
    if (brandName) {
      getInventory(isBrandChange.current);
    }
  }, [brandName, selectedClassifications]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Daily Inventory Levels</h2>
      <DailyInventoryForm
        brandName={brandName}
        setBrandName={setBrandName}
        classifications={classifications}
        selectedClassifications={selectedClassifications}
        handleSelectClassification={handleSelectClassification}
        showB2B={showB2B}
        showB2C={showB2C}
        handleB2BToggle={handleB2BToggle}
        handleB2CToggle={handleB2CToggle}
        loading={loading}
      />
      <DailyInventoryTable
        products={products}
        handleOpen={handleOpen}
        getInventoryDetails={getInventoryDetails}
        showB2B={showB2B}
        showB2C={showB2C}
        loading={loading}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <IconButton
                onClick={handleClose}
                variant="contained"
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <DailyInventoryGraph inventoryData={inventoryData} />
        </Box>
      </Modal>
    </div>
  );
};

export default DailyInventoryLevels;

import styles from "./Styles.module.css";
import { toast } from "react-toastify";
import {
  handleCellEdit,
  saveRevenueChanges,
} from "../../Utils/helperFunctions";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  TableCell,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/system";

const SalesForecastTable = ({
  brandName,
  fy,
  channels,
  salesPrediction,
  revenues,
  setRevenues,
  getSalesPrediction,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const totalRow = {
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    jan: 0,
    feb: 0,
    mar: 0,
  };
  Object.values(salesPrediction).forEach((sales) => {
    if (sales.quantity) {
      totalRow["apr"] += sales.quantity["apr"] ? sales.quantity["apr"] : 0;
      totalRow["may"] += sales.quantity["may"] ? sales.quantity["may"] : 0;
      totalRow["jun"] += sales.quantity["jun"] ? sales.quantity["jun"] : 0;
      totalRow["jul"] += sales.quantity["jul"] ? sales.quantity["jul"] : 0;
      totalRow["aug"] += sales.quantity["aug"] ? sales.quantity["aug"] : 0;
      totalRow["sep"] += sales.quantity["sep"] ? sales.quantity["sep"] : 0;
      totalRow["oct"] += sales.quantity["oct"] ? sales.quantity["oct"] : 0;
      totalRow["nov"] += sales.quantity["nov"] ? sales.quantity["nov"] : 0;
      totalRow["dec"] += sales.quantity["dec"] ? sales.quantity["dec"] : 0;
      totalRow["jan"] += sales.quantity["jan"] ? sales.quantity["jan"] : 0;
      totalRow["feb"] += sales.quantity["feb"] ? sales.quantity["feb"] : 0;
      totalRow["mar"] += sales.quantity["mar"] ? sales.quantity["mar"] : 0;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateRevenueChanges = async () => {
    try {
      const response = await saveRevenueChanges(
        setRevenues,
        revenues,
        brandName,
        fy
      );
      if (response.data.message === "OK") {
        toast("Data updated successfully");
        getSalesPrediction();
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  const handleValidation = (event) => {
    if (
      isNaN(event.target.innerText) ||
      (event.target.attributes.name &&
        event.target.attributes.name.nodeValue === "confidence_score" &&
        +event.target.innerText > 200)
    ) {
      event.target.style.backgroundColor = "#FF7F7F";
    } else {
      event.target.style.backgroundColor = "transparent";
    }
  };

  const handleFocus = (event) => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(event.target);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Paper sx={{ marginTop: "2em" }}>
      <TableContainer
        sx={{ maxHeight: 700, background: "white" }}
        component={Paper}
      >
        <Table
          className={styles.table}
          aria-label="customized table"
          sx={loading ? { height: 300 } : { maxHeight: 660 }}
        >
          <TableHead className={styles.table_head}>
            <TableRow>
              <TableCell
                className={styles.channel_column_head}
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Channel
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Apr
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                May
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Jun
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Jul
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Aug
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Sep
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Oct
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Nov
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Dec
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Jan
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Feb
              </TableCell>
              <TableCell
                sx={{ color: "white", fontWeight: "bold" }}
                align="center"
              >
                Mar
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "380%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableBody>
              {channels
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (channel, i) =>
                    channel !== "message" && (
                      <TableRow key={i + 1} hover>
                        <TableCell
                          className={styles.channel_columns}
                          component="th"
                          scope="row"
                        >
                          {channel}
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "april");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].apr
                              ? salesPrediction[channel]["quantity"].apr
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "april"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].apr
                                ? salesPrediction[channel]["confidence_score"]
                                    .apr
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "may");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].may
                              ? salesPrediction[channel]["quantity"].may
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(e, setRevenues, channel, "may");
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].may
                                ? salesPrediction[channel]["confidence_score"]
                                    .may
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "june");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].jun
                              ? salesPrediction[channel]["quantity"].jun
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(e, setRevenues, channel, "june");
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].jun
                                ? salesPrediction[channel]["confidence_score"]
                                    .jun
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "july");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].jul
                              ? salesPrediction[channel]["quantity"].jul
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(e, setRevenues, channel, "july");
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].jul
                                ? salesPrediction[channel]["confidence_score"]
                                    .jul
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "august");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].aug
                              ? salesPrediction[channel]["quantity"].aug
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "august"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].aug
                                ? salesPrediction[channel]["confidence_score"]
                                    .aug
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "september"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].sep
                              ? salesPrediction[channel]["quantity"].sep
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "september"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].sep
                                ? salesPrediction[channel]["confidence_score"]
                                    .sep
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "october"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].oct
                              ? salesPrediction[channel]["quantity"].oct
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "october"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].oct
                                ? salesPrediction[channel]["confidence_score"]
                                    .oct
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "november"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].nov
                              ? salesPrediction[channel]["quantity"].nov
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "november"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].nov
                                ? salesPrediction[channel]["confidence_score"]
                                    .nov
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "december"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].dec
                              ? salesPrediction[channel]["quantity"].dec
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "december"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].dec
                                ? salesPrediction[channel]["confidence_score"]
                                    .dec
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "january"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].jan
                              ? salesPrediction[channel]["quantity"].jan
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "january"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].jan
                                ? salesPrediction[channel]["confidence_score"]
                                    .jan
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(
                                e,
                                setRevenues,
                                channel,
                                "february"
                              );
                            }}
                          >
                            {salesPrediction[channel]["quantity"].feb
                              ? salesPrediction[channel]["quantity"].feb
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "february"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].feb
                                ? salesPrediction[channel]["confidence_score"]
                                    .feb
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                          <span
                            className={styles.quantity}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                              }
                            }}
                            onFocus={(e) => handleFocus(e)}
                            onInput={(e) => {
                              if (e.target.innerText.includes(",")) {
                                e.target.innerText =
                                  e.target.innerText.replaceAll(",", "");
                              }
                              handleValidation(e);
                              handleCellEdit(e, setRevenues, channel, "march");
                            }}
                          >
                            {salesPrediction[channel]["quantity"].mar
                              ? salesPrediction[channel]["quantity"].mar
                              : 0}
                          </span>
                          <div className={styles.conf_score_container}>
                            <span
                              name={"confidence_score"}
                              className={styles.confidence_score}
                              contentEditable
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                }
                              }}
                              onFocus={(e) => handleFocus(e)}
                              onInput={(e) => {
                                if (e.target.innerText.includes(",")) {
                                  e.target.innerText =
                                    e.target.innerText.replaceAll(",", "");
                                }
                                handleValidation(e);
                                handleCellEdit(
                                  e,
                                  setRevenues,
                                  channel,
                                  "march"
                                );
                              }}
                            >
                              {salesPrediction[channel]["confidence_score"].mar
                                ? salesPrediction[channel]["confidence_score"]
                                    .mar
                                : 100}
                            </span>
                            <span>%</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                )}
              <TableRow className={styles.totalRow}>
                <TableCell className={styles.channel_columns}>Total</TableCell>
                <TableCell className={styles.cell}>{totalRow["apr"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["may"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["jun"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["jul"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["aug"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["sep"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["oct"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["nov"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["dec"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["jan"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["feb"]}</TableCell>
                <TableCell className={styles.cell}>{totalRow["mar"]}</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          bottom: 0,
          right: 0,
          left: 0,
          top: 0,
          zIndex: 2,
          backgroundColor: "white",
        }}
      >
        <Button
          id={styles.saveButton}
          onClick={() => updateRevenueChanges()}
          disabled={revenues.length ? false : true}
        >
          Save
        </Button>
        <TablePagination
          count={channels.length - 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default SalesForecastTable;

import React, { useEffect, useState } from "react";
import styles from "./AdhocReplenishments.module.css";
import AdhocReplenishmentForm from "../../Components/AdhocReplenishments/AdhocReplenishmentForm";
import AdhocReplenishmentTable from "../../Components/AdhocReplenishments/AdhocReplenishmentTable";
import { fetchAdhocReplenishment } from "../../Utils/helperFunctions";
import { Box, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";

const AdhocReplenishment = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [products, setProducts] = useState([]);
  const [filterProductClasses, setFilterProductClasses] = useState([]);

  const getAdhocReplenishment = async () => {
    setLoading(true);
    setFilterProductClasses([]);
    setProducts([]);
    const postData = { brand: brandName };
    try {
      const response = await fetchAdhocReplenishment(postData);
      if (response.status === 200) {
        setFilterProductClasses(response.data.filtered_product_classes);
        setProducts(response.data.products);
        setLoading(false);
      }
    } catch (error) {
      toast("Error while get adhoc replenishments!");
      console.log("Error while get adhoc replenishment: ", error);
      setLoading(false);
    }
  };

  const removeFilter = async () => {
    const postData = { brand: brandName, include_muda: true };
    try {
      const response = await fetchAdhocReplenishment(postData);
      setFilterProductClasses([]);
      setProducts(response.data.products);
    } catch (error) {
      console.log("Error while removing filter: ", error);
    }
  };

  useEffect(() => {
    if (brandName) {
      getAdhocReplenishment();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Adhoc Replenishments</h2>
      <AdhocReplenishmentForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <Box className={styles.filterProducts}>
        <Box>
          <Typography>
            Products removed: {filterProductClasses.join(", ")}
          </Typography>
        </Box>
        <Box>
          <Button onClick={removeFilter}>Remove Filter</Button>
        </Box>
      </Box>
      <AdhocReplenishmentTable products={products} loading={loading} />
    </div>
  );
};

export default AdhocReplenishment;

import React, { useEffect, useState } from "react";
import ChannelForm from "../../Components/Channel/ChannelForm";
import {
  createChannel,
  fetchChannels,
  updateChannel,
  fetchBrands,
} from "../../Utils/helperFunctions";
import styles from "./Channel.module.css";
import { Button, Divider, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ChannelCreateForm from "../../Components/Channel/ChannelCreateForm";
import { toast } from "react-toastify";
import ChannelUpdateForm from "../../Components/Channel/ChannelUpdateForm";
import ChannelTable from "../../Components/Channel/ChannelTable";
import CloseIcon from "@mui/icons-material/Close";

const Channel = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [channels, setChannels] = useState([]);
  const [b2bTypes, setB2bTypes] = useState([]);
  const [ucChannels, setUcChannels] = useState([]);

  // Channel creation credentials
  const [createChannelName, setCreateChannelName] = useState("");
  const [createB2bType, setCreateB2bType] = useState();
  const [createSelectedUcChannels, setCreateSelectedUcChannels] = useState([]);
  const [openChCreateForm, setOpenChCreateForm] = useState(false);
  const handleChCreateFormOpen = () => setOpenChCreateForm(true);
  const handleChCreateFormClose = () => setOpenChCreateForm(false);

  // Channel update credentials
  const [updateSelectedChannel, setUpdateSelectedChannel] = useState();
  const [updateChannelName, setUpdateChannelName] = useState("");
  const [updateB2bType, setUpdateB2bType] = useState();
  const [updateSelectedUcChannels, setUpdateSelectedUcChannels] = useState([]);
  const [updateCurrentChannelName, setUpdateCurrentChannelName] = useState("");
  const [updateCurrentB2bType, setUpdateCurrentB2bType] = useState();
  const [openChUpdateForm, setOpenChUpdateForm] = useState(false);
  const handleChUpdateFormOpen = () => setOpenChUpdateForm(true);
  const handleChUpdateFormClose = () => setOpenChUpdateForm(false);

  const getChannels = async () => {
    setLoading(true);
    setChannels([]);
    setB2bTypes([]);
    setUcChannels([]);
    const postData = { brand: brandName };
    try {
      const response = await fetchChannels(postData);
      if (response.status === 200) {
        setChannels(response.data.channels);
        setB2bTypes(response.data.b2b_types);
        setUcChannels(response.data.unicommerce_channels);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while get channels!");
      console.log("Error while get channels: ", error);
      setLoading(false);
    }
  };

  const createNewChannel = async (e) => {
    e.preventDefault();
    const add_uc_channels = ucChannels
      .filter((elem) => {
        if (createSelectedUcChannels.includes(elem.name)) {
          return elem;
        }
      })
      .map((elem) => {
        return { unicommerce_channel_id: elem.id };
      });

    const postData = add_uc_channels[0]
      ? {
          brand: brandName,
          name: createChannelName,
          b2b_type: createB2bType,
          add_uc_channels,
        }
      : {
          brand: brandName,
          name: createChannelName,
          b2b_type: createB2bType,
        };

    try {
      const response = await createChannel(postData);

      if (response.data && response.data.message === "OK") {
        toast.success("Channel created.");
        handleChCreateFormClose();
        setCreateChannelName("");
        setCreateB2bType(b2bTypes[0][0]);
        setCreateSelectedUcChannels([]);
        getChannels();
      }
    } catch (error) {
      console.log("Error while creating new channel: ", error);
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors.non_field_errors
      ) {
        toast.error(error.response.data.errors.non_field_errors[0]);
      }
    }
  };

  const updateExistingChannel = async (e) => {
    e.preventDefault();
    const ucChannelsBelongsToSelectedChannel = ucChannels
      .filter((uc) => uc.channel === updateSelectedChannel)
      .map((elem) => elem.name);

    const addUcChannels = updateSelectedUcChannels
      .filter((elem) => {
        if (!ucChannelsBelongsToSelectedChannel.includes(elem)) return elem;
      })
      .map((elem) => {
        return elem;
      });

    const remUcChannels = ucChannelsBelongsToSelectedChannel
      .filter((elem) => {
        if (!updateSelectedUcChannels.includes(elem)) return elem;
      })
      .map((elem) => {
        return elem;
      });

    const add_uc_channels = ucChannels
      .filter((elem) => {
        if (addUcChannels.includes(elem.name)) return elem;
      })
      .map((elem) => {
        return { unicommerce_channel_id: elem.id };
      });

    const rem_uc_channels = ucChannels
      .filter((elem) => {
        if (remUcChannels.includes(elem.name)) return elem;
      })
      .map((elem) => {
        return { unicommerce_channel_id: elem.id };
      });

    if (
      add_uc_channels.length === 0 &&
      rem_uc_channels.length === 0 &&
      updateChannelName === updateCurrentChannelName &&
      updateB2bType === updateCurrentB2bType
    ) {
      toast.info("No changes found to update channel");
      return;
    }

    const postData = {
      brand: brandName,
      name: updateChannelName,
      channel_id: updateSelectedChannel,
      b2b_type: updateB2bType,
      add_uc_channels,
      rem_uc_channels,
    };

    try {
      const response = await updateChannel(postData);

      if (response.data && response.data.message === "OK") {
        toast.success("Channel updated.");
        handleChUpdateFormClose();
        getChannels();
      }
    } catch (error) {
      console.log("Error while updating the channel: ", error);
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors.non_field_errors
      ) {
        toast.error(error.response.data.errors.non_field_errors[0]);
      }
    }
  };

  useEffect(() => {
    getChannels();
  }, [brandName]);

  useEffect(() => {
    b2bTypes[0] && setCreateB2bType(b2bTypes[0][0]);
  }, [b2bTypes]);

  useEffect(() => {
    channels[0] && setUpdateSelectedChannel(channels[0].id);
  }, [channels]);

  useEffect(() => {
    if (updateSelectedChannel) {
      const channel = channels.filter(
        (ch) => ch.id === updateSelectedChannel
      )[0];
      setUpdateChannelName(channel.name);
      setUpdateCurrentChannelName(channel.name);
      setUpdateB2bType(channel.b2b_type);
      setUpdateCurrentB2bType(channel.b2b_type);
      setUpdateSelectedUcChannels(
        ucChannels
          .filter((uc) => uc.channel === channel.id)
          .map((elem) => elem.name)
      );
    }
  }, [updateSelectedChannel]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Channel</h2>
      <Box className={styles.channelFormBox}>
        <ChannelForm
          brandName={brandName}
          setBrandName={setBrandName}
          loading={loading}
        />
        <button
          onClick={handleChCreateFormOpen}
          className={styles.createNewChannelButton}
        >
          CREATE NEW CHANNEL
        </button>
      </Box>
      {/* Channel Create Form Modal */}
      <Modal
        open={openChCreateForm}
        onClose={handleChCreateFormClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalBox}>
          <Button
            onClick={handleChCreateFormClose}
            sx={{
              color: "black",
              height: "60px",
              borderRadius: "50%",
              position: "absolute",
              right: "0",
              top: "0",
              marginTop: "5px",
              marginRight: "5px",
            }}
          >
            <CloseIcon className={styles.closeIcon} />
          </Button>
          <Typography variant="h5" className={styles.channelCreateFormHeading}>
            Create New Channel
          </Typography>
          <Divider className={styles.devider} />
          <ChannelCreateForm
            b2bTypes={b2bTypes}
            ucChannels={ucChannels}
            createSelectedUcChannels={createSelectedUcChannels}
            setCreateSelectedUcChannels={setCreateSelectedUcChannels}
            createB2bType={createB2bType}
            setCreateB2bType={setCreateB2bType}
            createChannelName={createChannelName}
            setCreateChannelName={setCreateChannelName}
            createNewChannel={createNewChannel}
          />
        </Box>
      </Modal>

      {/* Channel update form modal */}
      <Modal
        open={openChUpdateForm}
        onClose={handleChUpdateFormClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalBox}>
          <Button
            onClick={handleChUpdateFormClose}
            sx={{
              color: "black",
              height: "60px",
              borderRadius: "50%",
              position: "absolute",
              right: "0",
              top: "0",
              marginTop: "5px",
              marginRight: "5px",
            }}
          >
            <CloseIcon className={styles.closeIcon} />
          </Button>
          <Typography variant="h5" className={styles.channelCreateFormHeading}>
            Update Channel
          </Typography>
          <Divider className={styles.devider} />
          <ChannelUpdateForm
            channels={channels}
            b2bTypes={b2bTypes}
            updateSelectedChannel={updateSelectedChannel}
            setUpdateSelectedChannel={setUpdateSelectedChannel}
            ucChannels={ucChannels}
            updateChannelName={updateChannelName}
            setUpdateChannelName={setUpdateChannelName}
            updateB2bType={updateB2bType}
            setUpdateB2bType={setUpdateB2bType}
            updateSelectedUcChannels={updateSelectedUcChannels}
            setUpdateSelectedUcChannels={setUpdateSelectedUcChannels}
            updateExistingChannel={updateExistingChannel}
          />
        </Box>
      </Modal>
      <ChannelTable
        channels={channels}
        b2bTypes={b2bTypes}
        ucChannels={ucChannels}
        setUpdateSelectedChannel={setUpdateSelectedChannel}
        handleChUpdateFormOpen={handleChUpdateFormOpen}
        loading={loading}
      />
    </div>
  );
};

export default Channel;

import ReactEcharts from "echarts-for-react";

const OutOfStockGraph = ({
  stockLossesData,
  setProductStockLoss,
  dateRange,
  setDateOnGraph,
  setForecast,
  loading,
}) => {
  const stockLoss = [];
  const forecastStockLoss = [];

  stockLossesData.forEach((data, index) => {
    if (data.forecast && index !== 0 && !stockLossesData[index - 1].forecast) {
      stockLoss.push(data.out_of_stock_loss);
      forecastStockLoss.push(data.out_of_stock_loss);
    } else if (data.forecast) {
      stockLoss.push(null);
      forecastStockLoss.push(data.out_of_stock_loss);
    } else {
      stockLoss.push(data.out_of_stock_loss);
      forecastStockLoss.push(null);
    }
  });

  const option = {
    legend: {
      data: ["Stock Loss", "Forecasted Stock Loss"],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: function (params) {
        const date = params[0].axisValue;
        const value =
          typeof params[0].value === "number"
            ? params[0].value
            : params[1].value;
        return `${date}<br/>${params[0].seriesName}: ₹${Math.round(
          value
        ).toLocaleString()}`;
      },
    },
    xAxis: {
      type: "category",
      data: stockLossesData.map((data) => data.date),
      name: "Date (YYYY-MM-DD)",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 20,
      },
    },
    yAxis: {
      type: "value",
      name: "Stock Loss (₹)",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 50,
      },
    },
    series: [
      {
        name: "Stock Loss",
        data: stockLoss,
        type: "line",
        lineStyle: {
          color: "blue",
        },
        symbolSize: 10,
      },
      {
        name: "Forecasted Stock Loss",
        data: forecastStockLoss,
        type: "line",
        lineStyle: {
          color: "red",
        },
        itemStyle: {
          color: "red",
        },
        symbolSize: 10,
      },
    ],
    graphic: {
      elements: [
        {
          type: "text",
          right: "5%",
          top: "top",
          style: {
            text: dateRange.split("~").join(" - "),
            fontSize: 14,
            fontWeight: "bold",
            fill: "#333",
            textAlign: "right",
          },
        },
      ],
    },
    width: "85%",
  };

  const onChartClick = (params) => {
    if (params.dataIndex >= 0) {
      const clickedData = stockLossesData[params.dataIndex];
      setDateOnGraph(clickedData.date);
      setForecast(clickedData.forecast);
      setProductStockLoss(clickedData.products);
    }
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <ReactEcharts option={option} onEvents={onEvents} showLoading={loading} />
  );
};

export default OutOfStockGraph;

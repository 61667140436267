import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Signin.module.css";
import {
  fetchBrands,
  isAuthenticated,
  signin,
} from "../../Utils/helperFunctions";
import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
import { toast } from "react-toastify";
import AppName from "../../Components/AppName/AppName";
import context from "../../Contexts/context";

const paperStyle = {
  padding: 40,
  width: 500,
  margin: "20px auto",
};

const avatarStyle = { backgroundColor: "black" };

const btnstyle = {
  margin: "1em 0",
  backgroundColor: "black",
  color: "white",
};

const textFieldStyle = {
  margin: "0 0 1em 0",
};

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const { setToken, setBrands } = useContext(context);
  const navigate = useNavigate();

  const handleSignin = async (e) => {
    e.preventDefault();
    try {
      const response = await signin(username, password);
      if (response.data.message === "OK") {
        const brands = await fetchBrands();
        if (brands.status === 200) {
          setBrands(brands.data);
          if (!localStorage.getItem("brand")) {
            localStorage.setItem(
              "brand",
              JSON.stringify({
                name: brands.data[0].name,
                year: new Date().getFullYear(),
              })
            );
          }
        }
        toast("Successfully logged in");
        const auth_response = await isAuthenticated();
        setToken(auth_response.data.authenticated);
        navigate("/");
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setIsError(true);
    }
  };

  return (
    <div className={styles.container}>
      <AppName />
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <Avatar style={avatarStyle} />
            <h2 className={styles.heading}>Sign In</h2>
          </Grid>
          <form onSubmit={handleSignin}>
            <TextField
              style={textFieldStyle}
              error={isError}
              label="Username"
              placeholder="Enter username"
              variant="outlined"
              fullWidth
              required
              autoFocus
              onChange={(e) => {
                setUsername(e.target.value);
                setIsError(false);
              }}
            />
            <TextField
              style={textFieldStyle}
              error={isError}
              helperText={isError && message}
              label="Password"
              placeholder="Enter password"
              type="password"
              variant="outlined"
              fullWidth
              required
              onChange={(e) => {
                setPassword(e.target.value);
                setIsError(false);
              }}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </Grid>
    </div>
  );
};

export default Signin;

import React, { useEffect, useState } from "react";
import styles from "./InventoryScore.module.css";
import InventoryScoreForm from "../../Components/InventoryScore/InventoryScoreForm";
import { toast } from "react-toastify";
import { fetchInventoryScore } from "../../Utils/helperFunctions";
import InventoryScoreTable from "../../Components/InventoryScore/InventoryScoreTable";

const InventoryScore = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [monthYear, setMonthYear] = useState(new Date());
  const [scores, setScores] = useState({});

  const getInventoryScore = async () => {
    setLoading(true);
    setScores({});
    const postData = {
      brand: brandName,
      month: monthYear.getMonth() + 1,
      year: monthYear.getFullYear(),
    };
    try {
      const response = await fetchInventoryScore(postData);
      setScores(response.data.scores);
      setLoading(false);
    } catch (error) {
      toast.error("Unable to get inventory score!");
      console.log("Error while get inventory score: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brandName && monthYear) {
      getInventoryScore();
    }
  }, [brandName, monthYear]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Inventory Score</h2>
      <InventoryScoreForm
        brandName={brandName}
        setBrandName={setBrandName}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
        loading={loading}
      />
      <InventoryScoreTable
        scores={scores}
        monthYear={monthYear}
        loading={loading}
      />
    </div>
  );
};

export default InventoryScore;

import ReactEcharts from "echarts-for-react";

const DailySalesGraph = ({ inventoryData }) => {
  const option = {
    title: { text: "Daily Sales" },
    legend: {
      data: ["Units Sold", "Forecast"],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      type: "category",
      data: inventoryData.map((data) => data.sale_date),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Units Sold",
        data: inventoryData.map((data) => (data.forecast ? null : data.total)),
        type: "bar",
        color: "blue",
        barWidth: "60%",
      },
      {
        name: "Forecast",
        data: inventoryData.map((data, index) =>
          data.forecast ? data.total : null
        ),
        type: "bar",
        color: "red",
        barWidth: "60%",
      },
    ],
  };
  return <ReactEcharts style={{ height: "500px" }} option={option} />;
};

export default DailySalesGraph;

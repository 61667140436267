import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import styles from "./Styles.module.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { dateFormat } from "../../Utils/helperFunctions";

const DailySalesForm = ({
  brandName,
  setBrandName,
  dateRange,
  setDateRange,
  loading,
}) => {
  const [firstDate, lastDate] = dateRange.split("~");

  const [selectionRange, setSelectionRange] = useState([
    new Date(firstDate),
    new Date(lastDate),
  ]);

  const handleDateRangeSelection = (ranges) => {
    if (ranges) {
      let firstDate = new Date(
        ranges[0].getFullYear(),
        ranges[0].getMonth(),
        1
      );
      let lastDate = new Date(
        ranges[1].getFullYear(),
        ranges[1].getMonth() + 1,
        0
      );

      // Calculate the difference in months
      let startYear = firstDate.getFullYear();
      let startMonth = firstDate.getMonth();
      let endYear = lastDate.getFullYear();
      let endMonth = lastDate.getMonth();

      let monthDifference =
        (endYear - startYear) * 12 + (endMonth - startMonth);

      // Check if the range is more than 12 months
      if (monthDifference > 11) {
        toast.info("Range must be within 12 months.");
        return;
      }

      let startDate = dateFormat(firstDate);
      let endDate = dateFormat(lastDate);
      setSelectionRange(ranges);
      setDateRange(`${startDate}~${endDate}`);
    } else {
      setSelectionRange(ranges);
    }
  };

  return (
    <form className={styles.form}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox}>
        <label className={styles.label}>Date Range</label>
        <DateRangePicker
          format="MMM yyyy"
          className={styles.dateRangePicker}
          size="sm"
          onChange={handleDateRangeSelection}
          value={selectionRange}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default DailySalesForm;

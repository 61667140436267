import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "./Styles.module.css";
import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const Row = ({ row, loading }) => {
  const gridRef = useRef();
  const [open, setOpen] = useState(false);

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 350,
      flex: 3,
    },
    {
      field: "doh",
      headerName: "DOH",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellDataType: "text",
    },
  ];

  const rows = row.products.map((product, index) => {
    return {
      id: index + 1,
      sku: product.sku,
      name: product.name,
      doh:
        typeof product.doh === "number" ? Math.round(product.doh) : product.doh,
    };
  });

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Inventory Health(${brand.name}) Classification(${
        row.classification
      }) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        className={styles.tableRow}
        style={{ height: 10 }}
      >
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            className={styles.iconButton}
          >
            {open ? (
              <KeyboardArrowUpIcon className={styles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
          align="left"
        >
          {row["classification"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
          align="center"
        >
          {row["0"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
          align="center"
        >
          {row["1-30"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
          align="center"
        >
          {row["30-90"]}
        </TableCell>
        <TableCell
          className={styles.tableCell}
          style={{ height: "auto !important" }}
          align="center"
        >
          {row[">90"]}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper
              sx={{
                height: rows.length < 12 ? "auto" : "500px",
                margin: rows.length < 12 ? "1em" : "1em 1em 4.5em 1em",
              }}
              className={`ag-theme-quartz ${styles.tableContainer}`}
            >
              <Box className={styles.customTableHeader}>
                <Button
                  className="exportButton"
                  onClick={onBtnExport}
                  startIcon={<SaveAltIcon />}
                >
                  Export
                </Button>
                <TextField
                  type="text"
                  size="small"
                  label="Search"
                  id="filter-text-box"
                  className={styles.searchField}
                  onInput={onFilterTextBoxChanged}
                />
              </Box>
              {loading ? (
                <Box sx={{ height: "500px" }} className="ag-theme-quartz">
                  <AgGridReact columnDefs={columns} rowData={null} />
                </Box>
              ) : (
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columns}
                  rowData={rows}
                  domLayout={rows.length < 12 ? "autoHeight" : null}
                  rowHeight={35}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  enableCellTextSelection={true}
                  suppressMovableColumns={true}
                />
              )}
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const InventoryHealthTable = ({ reports, loading }) => {
  const inventoryHealth = [];
  const totalRowDict = { 0: 0, "1-30": 0, "30-90": 0, ">90": 0 };

  for (let [key, value] of Object.entries(reports)) {
    inventoryHealth.push({
      classification: key,
      0: value["0"],
      "1-30": value["1-30"],
      "30-90": value["30-90"],
      ">90": value[">90"],
      products: value["products"],
    });
  }

  inventoryHealth.map((elem) => {
    totalRowDict[0] += elem[0];
    totalRowDict["1-30"] += elem["1-30"];
    totalRowDict["30-90"] += elem["30-90"];
    totalRowDict[">90"] += elem[">90"];
  });

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        marginTop: "2em",
        marginBottom: "3em",
        height: inventoryHealth.length === 0 && "300px",
        maxHeight: !loading && "750px",
      }}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow className={styles.tableRowHead}>
            <TableCell className={styles.tableCellHead} />
            <TableCell className={styles.tableCellHead} />
            <TableCell
              className={styles.tableCellHead}
              align="center"
              colSpan={4}
            >
              DOH
            </TableCell>
          </TableRow>
          <TableRow className={styles.tableSubRowHead}>
            <TableCell
              className={styles.tableCellHead}
              sx={{ width: "50px" }}
            />
            <TableCell className={styles.tableCellHead} align="left">
              Classification
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              {"0 Days"}
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              {"1-30 Days"}
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              {"30-90 Days"}
            </TableCell>
            <TableCell className={styles.tableCellHead} align="center">
              {">90 Days"}
            </TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            {new Array(3).fill(1).map((row, index) => {
              return (
                <TableRow key={row + index}>
                  {new Array(6).fill(1).map((column, index) => {
                    return (
                      <TableCell
                        key={column + index}
                        className={styles.tableCell}
                      >
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        ) : inventoryHealth.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  position: "relative",
                  top: "80px",
                  left: "50%",
                  border: "none",
                }}
              >
                No Rows
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {inventoryHealth.map((row) => (
              <Row key={row.classification} row={row} loading={loading} />
            ))}
            <TableRow className={styles.totalRow}>
              <TableCell></TableCell>
              <TableCell>Total</TableCell>
              <TableCell align="center">{totalRowDict[0]}</TableCell>
              <TableCell align="center">{totalRowDict["1-30"]}</TableCell>
              <TableCell align="center">{totalRowDict["30-90"]}</TableCell>
              <TableCell align="center">{totalRowDict[">90"]}</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default InventoryHealthTable;

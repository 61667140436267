import React, { useEffect, useState } from "react";
import styles from "./Classification.module.css";
import BrandSelectionForm from "../../Components/BrandSelectionForm/BrandSelectionForm";
import { toast } from "react-toastify";
import { fetchClassification } from "../../Utils/helperFunctions";
import ClassificationTable from "../../Components/Classification/ClassificationTable";

const Classification = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [loading, setLoading] = useState(false);
  const [classifications, setClassifications] = useState([]);

  const getClassification = async () => {
    setLoading(true);
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(response.data.classifications);
      setLoading(false);
    } catch (error) {
      toast.error("Error while get classifications!");
      console.log("Error while get classifications: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brandName) {
      getClassification();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Classifications</h2>
      <div className={styles.formBox}>
        <BrandSelectionForm
          brandName={brandName}
          setBrandName={setBrandName}
          loading={loading}
        />
      </div>
      <ClassificationTable
        brandName={brandName}
        classifications={classifications}
        getClassification={getClassification}
        loading={loading}
      />
    </div>
  );
};

export default Classification;

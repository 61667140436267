import { useCallback, useMemo, useRef } from "react";
import styles from "./Styles.module.css";
import { AgGridReact } from "@ag-grid-community/react";
import { Button, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const ConfidenceScoreTable = ({ salesPrediction, loading }) => {
  const gridRef = useRef();

  const columns = [
    {
      field: "channel",
      headerName: "Channel",
      minWidth: 150,
      flex: 2,
      cellStyle: { textAlign: "center", background: "whitesmoke" },
      pinned: true,
    },
    {
      field: "apr",
      headerName: "Apr",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "may",
      headerName: "May",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "jun",
      headerName: "Jun",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "jul",
      headerName: "Jul",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "aug",
      headerName: "Aug",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "sep",
      headerName: "Sep",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "oct",
      headerName: "Oct",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "nov",
      headerName: "Nov",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "dec",
      headerName: "Dec",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "jan",
      headerName: "Jan",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "feb",
      headerName: "Feb",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "mar",
      headerName: "Mar",
      minWidth: 100,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const totalRowDict = {
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    jan: 0,
    feb: 0,
    mar: 0,
  };

  const rows = Object.keys(salesPrediction)
    .filter((channel) => channel !== "message")
    .map((channel, index) => {
      return {
        id: index + 1,
        channel: channel,
        apr: (salesPrediction[channel]["quantity"].apr
          ? (+salesPrediction[channel]["quantity"].apr *
              +salesPrediction[channel]["confidence_score"].apr) /
            100
          : 0
        ).toFixed(0),
        may: (salesPrediction[channel]["quantity"].may
          ? (+salesPrediction[channel]["quantity"].may *
              +salesPrediction[channel]["confidence_score"].may) /
            100
          : 0
        ).toFixed(0),
        jun: (salesPrediction[channel]["quantity"].jun
          ? (+salesPrediction[channel]["quantity"].jun *
              +salesPrediction[channel]["confidence_score"].jun) /
            100
          : 0
        ).toFixed(0),
        jul: (salesPrediction[channel]["quantity"].jul
          ? (+salesPrediction[channel]["quantity"].jul *
              +salesPrediction[channel]["confidence_score"].jul) /
            100
          : 0
        ).toFixed(0),
        aug: (salesPrediction[channel]["quantity"].aug
          ? (+salesPrediction[channel]["quantity"].aug *
              +salesPrediction[channel]["confidence_score"].aug) /
            100
          : 0
        ).toFixed(0),
        sep: (salesPrediction[channel]["quantity"].sep
          ? (+salesPrediction[channel]["quantity"].sep *
              +salesPrediction[channel]["confidence_score"].sep) /
            100
          : 0
        ).toFixed(0),
        oct: (salesPrediction[channel]["quantity"].oct
          ? (+salesPrediction[channel]["quantity"].oct *
              +salesPrediction[channel]["confidence_score"].oct) /
            100
          : 0
        ).toFixed(0),
        nov: (salesPrediction[channel]["quantity"].nov
          ? (+salesPrediction[channel]["quantity"].nov *
              +salesPrediction[channel]["confidence_score"].nov) /
            100
          : 0
        ).toFixed(0),
        dec: (salesPrediction[channel]["quantity"].dec
          ? (+salesPrediction[channel]["quantity"].dec *
              +salesPrediction[channel]["confidence_score"].dec) /
            100
          : 0
        ).toFixed(0),
        jan: (salesPrediction[channel]["quantity"].jan
          ? (+salesPrediction[channel]["quantity"].jan *
              +salesPrediction[channel]["confidence_score"].jan) /
            100
          : 0
        ).toFixed(0),
        feb: (salesPrediction[channel]["quantity"].feb
          ? (+salesPrediction[channel]["quantity"].feb *
              +salesPrediction[channel]["confidence_score"].feb) /
            100
          : 0
        ).toFixed(0),
        mar: (salesPrediction[channel]["quantity"].mar
          ? (+salesPrediction[channel]["quantity"].mar *
              +salesPrediction[channel]["confidence_score"].mar) /
            100
          : 0
        ).toFixed(0),
      };
    });

  rows.forEach((row) => {
    totalRowDict["apr"] += +row["apr"] ? +row["apr"] : 0;
    totalRowDict["may"] += +row["may"] ? +row["may"] : 0;
    totalRowDict["jun"] += +row["jun"] ? +row["jun"] : 0;
    totalRowDict["jul"] += +row["jul"] ? +row["jul"] : 0;
    totalRowDict["aug"] += +row["aug"] ? +row["aug"] : 0;
    totalRowDict["sep"] += +row["sep"] ? +row["sep"] : 0;
    totalRowDict["oct"] += +row["oct"] ? +row["oct"] : 0;
    totalRowDict["nov"] += +row["nov"] ? +row["nov"] : 0;
    totalRowDict["dec"] += +row["dec"] ? +row["dec"] : 0;
    totalRowDict["jan"] += +row["jan"] ? +row["jan"] : 0;
    totalRowDict["feb"] += +row["feb"] ? +row["feb"] : 0;
    totalRowDict["mar"] += +row["mar"] ? +row["mar"] : 0;
  });

  const totalRow = [{ id: "total", channel: "Total", ...totalRowDict }];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Sales Forecast(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          pinnedBottomRowData={rows.length && totalRow}
          rowHeight={35}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
    </Paper>
  );
};

export default ConfidenceScoreTable;

import ReactEcharts from "echarts-for-react";

const DailyInventoryGraph = ({ inventoryData }) => {
  let dates = [];
  let positiveQuantity = [];
  let negativeQuantity = [];
  let positiveForecast = [];
  let negativeForecast = [];

  inventoryData.forEach((data, index) => {
    dates.push(data.date);
    if (index !== inventoryData.length - 1) {
      if (data.forecast) {
        if (data.quantity > 0) {
          positiveQuantity.push(null);
          negativeQuantity.push(null);
          positiveForecast.push(data.quantity);
          negativeForecast.push(null);
          if (inventoryData[index + 1].quantity < 0) {
            dates.push("");
            positiveQuantity.push(null);
            negativeQuantity.push(null);
            positiveForecast.push(0);
            negativeForecast.push(0);
          }
        } else if (data.quantity < 0) {
          positiveQuantity.push(null);
          negativeQuantity.push(null);
          positiveForecast.push(null);
          negativeForecast.push(data.quantity);
          if (inventoryData[index + 1].quantity > 0) {
            dates.push("");
            positiveQuantity.push(null);
            negativeQuantity.push(null);
            positiveForecast.push(0);
            negativeForecast.push(0);
          }
        } else {
          positiveQuantity.push(null);
          negativeQuantity.push(null);
          positiveForecast.push(data.quantity);
          negativeForecast.push(data.quantity);
        }
      } else {
        if (data.quantity > 0) {
          positiveQuantity.push(data.quantity);
          negativeQuantity.push(null);
          if (inventoryData[index + 1].forecast) {
            positiveForecast.push(data.quantity);
          } else {
            positiveForecast.push(null);
          }
          negativeForecast.push(null);
          if (inventoryData[index + 1].quantity < 0) {
            dates.push("");
            positiveQuantity.push(0);
            positiveForecast.push(null);
            if (inventoryData[index + 1].forecast) {
              negativeQuantity.push(null);
              negativeForecast.push(0);
            } else {
              negativeQuantity.push(0);
              negativeForecast.push(null);
            }
          }
        } else if (data.quantity < 0) {
          positiveQuantity.push(null);
          negativeQuantity.push(data.quantity);
          positiveForecast.push(null);
          if (inventoryData[index + 1].forecast) {
            negativeForecast.push(data.quantity);
          } else {
            negativeForecast.push(null);
          }
          if (inventoryData[index + 1].quantity > 0) {
            dates.push("");
            negativeQuantity.push(0);
            negativeForecast.push(null);
            if (inventoryData[index + 1].forecast) {
              positiveQuantity.push(null);
              positiveForecast.push(0);
            } else {
              positiveQuantity.push(0);
              positiveForecast.push(null);
            }
          }
        } else {
          positiveQuantity.push(data.quantity);
          negativeQuantity.push(data.quantity);
          positiveForecast.push(null);
          negativeForecast.push(null);
        }
      }
    } else {
      if (data.forecast) {
        if (data.quantity >= 0) {
          positiveQuantity.push(null);
          negativeQuantity.push(null);
          positiveForecast.push(data.quantity);
          negativeForecast.push(null);
        } else {
          positiveQuantity.push(null);
          negativeQuantity.push(null);
          positiveForecast.push(null);
          negativeForecast.push(data.quantity);
        }
      } else {
        if (data.quantity >= 0) {
          positiveQuantity.push(data.quantity);
          negativeQuantity.push(null);
          positiveForecast.push(null);
          negativeForecast.push(null);
        } else {
          positiveQuantity.push(null);
          negativeQuantity.push(data.quantity);
          positiveForecast.push(null);
          negativeForecast.push(null);
        }
      }
    }
  });

  const option = {
    title: { text: "Inventory Graph" },
    legend: {
      data: [
        "Inventory(+ve)",
        "Inventory(-ve)",
        "Forecast(+ve)",
        "Forecast(-ve)",
      ],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    xAxis: {
      type: "category",
      data: dates,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Inventory(+ve)",
        data: positiveQuantity,
        type: "line",
        lineStyle: {
          color: "blue",
        },
      },
      {
        name: "Inventory(-ve)",
        data: negativeQuantity,
        type: "line",
        lineStyle: {
          color: "blue",
          type: "dashed",
        },
      },
      {
        name: "Forecast(+ve)",
        data: positiveForecast,
        type: "line",
        lineStyle: {
          color: "red",
        },
        itemStyle: {
          color: "red",
        },
      },
      {
        name: "Forecast(-ve)",
        data: negativeForecast,
        type: "line",
        lineStyle: {
          color: "red",
          type: "dashed",
        },
        itemStyle: {
          color: "red",
        },
      },
    ],
  };
  return <ReactEcharts style={{ height: "500px" }} option={option} />;
};

export default DailyInventoryGraph;

import { Typography } from "@mui/material";
import styles from "./Styles.module.css";

const AppName = () => {
  return (
    <Typography variant="h3" className={styles.appName}>
      Doppler
    </Typography>
  );
};

export default AppName;

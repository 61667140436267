import { DateRangePicker } from "rsuite";
import Select from "react-select";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import styles from "./Styles.module.css";
import { useState } from "react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "25px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "25px",
    margin: "auto",
  }),
};

const OutOfStockForm = ({
  brandName,
  setBrandName,
  dateRange,
  setDateRange,
  classifications,
  selectedClassifications,
  handleSelectClassification,
  getStockLoss,
  loading,
}) => {
  const [firstDate, lastDate] = dateRange.split("~");

  const [selectionRange, setSelectionRange] = useState([
    new Date(firstDate),
    new Date(lastDate),
  ]);

  const handleDateRangeSelection = (ranges) => {
    if (ranges) {
      let startDate = new Date(ranges[0]).toISOString().slice(0, 10);
      let endDate = new Date(ranges[1]).toISOString().slice(0, 10);
      setSelectionRange(ranges);
      setDateRange(`${startDate}~${endDate}`);
    } else {
      setSelectionRange(ranges);
    }
  };

  return (
    <form className={styles.form}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox}>
        <label className={styles.label}>Date Range</label>
        <DateRangePicker
          className={styles.dateRangePicker}
          size="sm"
          onChange={handleDateRangeSelection}
          value={selectionRange}
          disabled={loading}
        />
      </div>
      <div className={styles.inputBox} style={{ marginLeft: "2em" }}>
        <label className={styles.label}>Classification</label>
        <Select
          styles={customStyles}
          placeholder="Select Classifications"
          closeMenuOnSelect={false}
          onMenuClose={getStockLoss}
          isMulti
          options={classifications}
          value={selectedClassifications}
          onChange={handleSelectClassification}
          className={styles.selectInput}
          isDisabled={loading}
        />
      </div>
    </form>
  );
};
export default OutOfStockForm;

import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";

const ChannelForm = ({ brandName, setBrandName, loading }) => {
  return (
    <BrandSelectionForm
      brandName={brandName}
      setBrandName={setBrandName}
      loading={loading}
    />
  );
};

export default ChannelForm;

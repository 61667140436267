import ReactEcharts from "echarts-for-react";

const breakProductName = (productName, width) =>
  productName
    .match(new RegExp(`(.{1,${width}})(\\s|$)|(.{1,${width}})`, "g"))
    .map((line) => line.trim());

const ProductStockLoss = ({
  productStockLoss,
  dateOnGraph,
  forecast,
  loading,
}) => {
  const option = {
    legend: {
      data: ["Stock Loss"],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: function (params) {
        const sku = params[0].axisValue;
        const value = params[0].value;
        return `SKU - ${sku}<br/>${params[0].seriesName} - ₹${Math.round(
          value
        ).toLocaleString()}<br/>Product Name - ${breakProductName(
          productStockLoss[params[0].dataIndex].name,
          55
        ).map((val) => val + "<br/>")}`;
      },
    },
    xAxis: {
      type: "category",
      data: productStockLoss.map((data) => data.sku),
      name: "SKU",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 20,
      },
    },
    yAxis: {
      type: "value",
      name: "Stock Loss ( ₹ )",
      nameLocation: "middle",
      nameTextStyle: {
        fontWeight: "bold",
        fontSize: 12,
        padding: 60,
      },
    },
    grid: {
      bottom: 100,
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
      },
    ],
    series: [
      {
        name: "Stock Loss",
        data: productStockLoss.map((data) => data.out_of_stock_loss),
        type: "bar",
        itemStyle: {
          color: forecast ? "red" : "blue",
        },
      },
    ],
    graphic: {
      elements: [
        {
          type: "text",
          right: "5%",
          top: "top",
          style: {
            text: dateOnGraph.split("~").join(" - "),
            fontSize: 14,
            fontWeight: "bold",
            fill: "#333",
            textAlign: "right",
          },
        },
      ],
    },
    height: "230px",
    width: "85%",
  };
  return (
    <ReactEcharts
      option={option}
      style={{ height: "100%" }}
      showLoading={loading}
    />
  );
};

export default ProductStockLoss;

import { Button, Box, Select, MenuItem, FormControl } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Styles.module.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SaveIcon from "@mui/icons-material/Save";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { toast } from "react-toastify";
import { updateInventoryNorm } from "../../Utils/helperFunctions";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const InventoryNormTable = ({
  brandName,
  inventoryNorm,
  loading,
  fy,
  setFy,
  years,
  getInventoryNorm,
}) => {
  const gridRef = useRef();
  let updatedInventoryNormDict = {};

  const rows = inventoryNorm.map((inventory, index) => {
    return { id: index + 1, ...inventory };
  });

  const financialYearComparator = (monthA, monthB) => {
    const getFinancialYearMonth = (month) => ((month + 8) % 12) + 1;
    const fyMonthA = getFinancialYearMonth(monthA);
    const fyMonthB = getFinancialYearMonth(monthB);
    return fyMonthA - fyMonthB;
  };

  const [columns] = useState([
    {
      field: "month",
      headerName: "Month",
      flex: 2,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      sortable: false,
      sort: "asc",
      comparator: financialYearComparator,
      cellRenderer: (params) => months[params.data.month],
    },
    {
      field: "inventory_norm_value",
      headerName: "Inventory Norm Value",
      flex: 2,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      editable: true,
    },
  ]);

  const onCellValueChanged = (row) => {
    updatedInventoryNormDict[months[row.data.month].toLowerCase()] =
      row.data.inventory_norm_value;
  };

  const handleSave = async () => {
    if (Object.values(updatedInventoryNormDict).length) {
      const postData = {
        brand: brandName,
        financial_year: fy,
        inventory_norm: updatedInventoryNormDict,
      };
      try {
        const response = await updateInventoryNorm(postData);
        if (response.status === 200) {
          getInventoryNorm();
          updatedInventoryNormDict = {};
          toast.success("Ineventory Norm updated successfully");
        }
      } catch (error) {
        toast.error("Error while updating inventory norm!");
        console.log("Error while updating inventory norm: ", error);
      }
    } else {
      toast.info("Nothing to update!");
    }
  };

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Inventory Norm(${brand.name}) ${new Date().toLocaleString()}`,
      processCellCallback: (params) => {
        if (params.column.colId === "month") {
          return months[params.value];
        }
        return params.value;
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  useEffect(() => {
    if (brandName && fy) {
      getInventoryNorm();
      localStorage.setItem(
        "brand",
        JSON.stringify({
          name: brandName,
          year: +fy.split("-")[0],
        })
      );
    }
  }, [brandName, fy]);


  return (
    <Box
      sx={{ maxHeight: "700px" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button onClick={onBtnExport} startIcon={<SaveAltIcon />}>
          Export
        </Button>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <label style={{ color: "grey" }}>Financial Year</label>
          <Select
            defaultValue={fy}
            sx={{ width: "150px" }}
            onChange={(e) => setFy(e.target.value)}
            size="small"
          >
            {years.map((year, index) => {
              return (
                <MenuItem value={year} key={index + 1}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button onClick={handleSave} startIcon={<SaveIcon />}>
          Save
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          domLayout="autoHeight"
          rowHeight={35}
          onCellValueChanged={onCellValueChanged}
        />
      )}
    </Box>
  );
};

export default InventoryNormTable;

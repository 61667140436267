import { Box } from "@mui/system";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import styles from "./Styles.module.css";
import { Checkbox, FormControlLabel } from "@mui/material";

const PurchaseOrdersForm = ({
  brandName,
  setBrandName,
  breachedFilter,
  handleBreachedFilter,
  loading,
}) => {
  return (
    <Box className={styles.formBox}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <FormControlLabel
        className={styles.checkbox}
        label="Breached Orders"
        labelPlacement="start"
        control={
          <Checkbox checked={breachedFilter} onChange={handleBreachedFilter} />
        }
      />
    </Box>
  );
};

export default PurchaseOrdersForm;

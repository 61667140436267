import React, { useContext, useEffect, useState } from "react";
import styles from "./InventoryCapital.module.css";
import { fetchWorkingCapitals } from "../../Utils/helperFunctions";
import { Box } from "@mui/system";
import InventoryCapitalForm from "../../Components/InventoryCapital/InventoryCapitalForm";
import InventoryCapitalGraph from "../../Components/InventoryCapital/InventoryCapitalGraph";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import context from "../../Contexts/context";

const InventoryCapital = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const { brands } = useContext(context);
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const temp = new Date(date);
  temp.setMonth(date.getMonth() - 3);
  const today = date.toISOString().split("T")[0];
  const twoDayBeforeDate = temp.toISOString().split("T")[0];
  const [dateRange, setDateRange] = useState(`${twoDayBeforeDate}~${today}`);
  const [brandData, setBrandData] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([
    { value: brand ? brand.name : "", label: brand ? brand.name : "" },
  ]);
  const [workingCapitalData, setWorkingCapitalData] = useState([]);
  const [currentWorkingCapital, setCurrentWorkingCapital] = useState("");

  const handleSelectedBrandChange = (selectedValues) => {
    setSelectedBrands(selectedValues);
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    getWorkingCapitals();
  };

  const getWorkingCapitals = async () => {
    let brandList = selectedBrands.map((brand) => brand.value);
    if (brandList.length === 0) {
      toast("Select at least one brand.");
      return;
    }
    setLoading(true);
    setWorkingCapitalData([]);
    setCurrentWorkingCapital("");
    let [firstDate, lastDate] = dateRange.split("~");
    const postData = {
      brand: brandList,
      date_start: firstDate,
      date_end: lastDate,
    };

    try {
      const response = await fetchWorkingCapitals(postData);
      if (response.status === 200) {
        setWorkingCapitalData(response.data.daily_working_capital);
        setCurrentWorkingCapital(response.data.current_working_capital);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors.non_field_errors[0], {
          autoClose: 3000,
        });
      }
      console.log("Error while get working capitals: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getWorkingCapitals();
  }, []);

  useEffect(() => {
    let selectBrandData = brands.map((brand) => {
      return { value: brand.name, label: brand.name };
    });
    setBrandData(selectBrandData);
  }, [brands]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Inventory Capital</h1>
      <InventoryCapitalForm
        formSubmit={formSubmit}
        brandData={brandData}
        handleSelectedBrandChange={handleSelectedBrandChange}
        selectedBrands={selectedBrands}
        dateRange={dateRange}
        setDateRange={setDateRange}
        loading={loading}
      />
      <Box className={styles.currentWorkingCapital}>
        <span>
          Current Inventory Capital -{" "}
          {"₹" +
            (currentWorkingCapital
              ? Math.round(currentWorkingCapital).toLocaleString()
              : "")}
        </span>
      </Box>
      <Box className={styles.graph}>
        {loading ? (
          <CircularProgress sx={{ marginTop: "120px" }} />
        ) : (
          <InventoryCapitalGraph workingCapitalData={workingCapitalData} />
        )}
      </Box>
    </div>
  );
};

export default InventoryCapital;

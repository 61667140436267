import React from "react";
import styles from "./Styles.module.css";
import { Typography } from "@mui/material";

const ProductUpdateForm = ({ updateProducts, setProductCsvFile }) => {
  return (
    <form
      className={styles.csvForm}
      onSubmit={updateProducts}
      encType="multipart/form-data"
    >
      <Typography className={styles.subFormHeading}>
        UPDATE SELECTED PRODUCTS
      </Typography>
      <label className={styles.modalCsvLabel}>Upload Csv File</label>
      <input
        placeholder="Upload Csv File"
        className={styles.modalCsvInput}
        type="file"
        accept=".csv"
        name="csvFile"
        onChange={(e) => {
          setProductCsvFile(e.target.files[0]);
        }}
      />
      <input
        className={styles.modalCsvUploadSubmitButton}
        type="submit"
        value={"UPLOAD"}
      />
    </form>
  );
};

export default ProductUpdateForm;

import styles from "./Styles.module.css";
import { Box } from "@mui/system";
import { Button, Paper, TextField, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useCallback, useMemo, useRef } from "react";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const DailyInventoryTable = ({
  products,
  handleOpen,
  getInventoryDetails,
  showB2C,
  showB2B,
  loading,
}) => {
  const gridRef = useRef();
  const totalRowsDict = {
    quantity: 0,
    forecast_drr: 0,
    b2c: 0,
    unicommerce: 0,
    fba: 0,
    myntra_sjit: 0,
    fbf: 0,
    b2b: 0,
    amazon_b2b: 0,
    total_value: 0,
  };
  const defaultColumns = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 350,
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "total_value",
      headerName: "₹ TOTAL VALUE",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <Tooltip title="Missing Cost">
            <ErrorOutlineIcon fontSize="small" color="error" />
          </Tooltip>
        ) : (
          "₹" + params.value
        );
      },
    },
    {
      field: "days_on_hand",
      headerName: "DAYS ON HAND",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "forecast_drr",
      headerName: "DRR FORECAST",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2c",
      headerName: "B2C",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "unicommerce",
      headerName: "Unicommerce",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "fba",
      headerName: "FBA",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "myntra_sjit",
      headerName: "MYNTRA SJIT",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "fbf",
      headerName: "FBF",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2b",
      headerName: "B2B",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "amazon_b2b",
      headerName: "AMAZON",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const defaultUnSelectedColumns = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 350,
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "total_value",
      headerName: "₹ TOTAL VALUE",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <Tooltip title="Missing Cost">
            <ErrorOutlineIcon fontSize="small" color="error" />
          </Tooltip>
        ) : (
          "₹" + params.value
        );
      },
    },
    {
      field: "days_on_hand",
      headerName: "DAYS ON HAND",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "forecast_drr",
      headerName: "DRR FORECAST",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2c",
      headerName: "B2C",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },

    {
      field: "b2b",
      headerName: "B2B",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const columnsB2C = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 350,
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "total_value",
      headerName: "₹ TOTAL VALUE",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <Tooltip title="Missing Cost">
            <ErrorOutlineIcon fontSize="small" color="error" />
          </Tooltip>
        ) : (
          "₹" + params.value
        );
      },
    },
    {
      field: "days_on_hand",
      headerName: "DAYS ON HAND",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "forecast_drr",
      headerName: "DRR FORECAST",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2c",
      headerName: "B2C",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "unicommerce",
      headerName: "Unicommerce",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "fba",
      headerName: "FBA",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "myntra_sjit",
      headerName: "MYNTRA SJIT",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "fbf",
      headerName: "FBF",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2b",
      headerName: "B2B",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const columnsB2B = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 350,
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "total_value",
      headerName: "₹ TOTAL VALUE",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        return params.value === 0 ? (
          <Tooltip title="Missing Cost">
            <ErrorOutlineIcon fontSize="small" color="error" />
          </Tooltip>
        ) : (
          "₹" + params.value
        );
      },
    },
    {
      field: "days_on_hand",
      headerName: "DAYS ON HAND",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "forecast_drr",
      headerName: "DRR FORECAST",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2c",
      headerName: "B2C",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "b2b",
      headerName: "B2B",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "amazon_b2b",
      headerName: "AMAZON",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const selectedColumns = showB2C
    ? showB2B
      ? defaultColumns
      : columnsB2C
    : showB2B
    ? columnsB2B
    : defaultUnSelectedColumns;

  const rows = products.map((product, index) => {
    totalRowsDict["quantity"] += product.quantity;
    totalRowsDict["forecast_drr"] +=
      Math.round(product.forecast_drr * 100) / 100;
    totalRowsDict["b2c"] +=
      product.b2c.unicommerce +
      product.b2c.fba +
      product.b2c.myntra_sjit +
      product.b2c.fbf;
    totalRowsDict["unicommerce"] += product.b2c.unicommerce;
    totalRowsDict["fba"] += product.b2c.fba;
    totalRowsDict["myntra_sjit"] += product.b2c.myntra_sjit;
    totalRowsDict["fbf"] += product.b2c.fbf;
    totalRowsDict["b2b"] += product.b2b.amazon;
    totalRowsDict["amazon_b2b"] += product.b2b.amazon;
    totalRowsDict["total_value"] += product.total_value;
    return {
      id: index + 1,
      sku: product.sku,
      name: product.name,
      quantity: product.quantity,
      days_on_hand: Math.round(product.days_on_hand * 100) / 100,
      forecast_drr: Math.round(product.forecast_drr * 100) / 100,
      b2c:
        product.b2c.unicommerce +
        product.b2c.fba +
        product.b2c.myntra_sjit +
        product.b2c.fbf,
      unicommerce: product.b2c.unicommerce,
      fba: product.b2c.fba,
      myntra_sjit: product.b2c.myntra_sjit,
      fbf: product.b2c.fbf,
      b2b: product.b2b.amazon,
      amazon_b2b: product.b2b.amazon,
      total_value: product.total_value,
    };
  });

  const totalRow = [
    {
      id: "total",
      sku: "Total",
      quantity: Math.round(totalRowsDict["quantity"]),
      forecast_drr: Math.round(totalRowsDict["forecast_drr"]),
      b2c: Math.round(totalRowsDict["b2c"]),
      unicommerce: Math.round(totalRowsDict["unicommerce"]),
      fba: Math.round(totalRowsDict["fba"]),
      myntra_sjit: Math.round(totalRowsDict["myntra_sjit"]),
      fbf: Math.round(totalRowsDict["fbf"]),
      b2b: Math.round(totalRowsDict["b2b"]),
      amazon_b2b: Math.round(totalRowsDict["amazon_b2b"]),
      total_value: Math.round(totalRowsDict["total_value"]),
    },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Daily Inventory(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={selectedColumns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : null}
          ref={gridRef}
          columnDefs={selectedColumns}
          rowData={rows}
          rowHeight={35}
          pinnedBottomRowData={rows.length && totalRow}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          onRowClicked={(params) => {
            if (params.data.id !== "total") {
              handleOpen();
              getInventoryDetails(params.data.sku);
            }
          }}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
    </Paper>
  );
};
export default DailyInventoryTable;

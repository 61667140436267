import React, { useCallback, useMemo, useRef } from "react";
import styles from "./Styles.module.css";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const MissingCostProductTable = ({ products }) => {
  const convertArrayToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
    return `${headers}\n${rows}`;
  };

  const downloadOdersAsCsv = () => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const csv = convertArrayToCSV([
      ...products.map((product) => {
        return {
          SKU: product,
        };
      }),
    ]);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Missing Cost Products(${
      brand.name
    }) ${new Date().toLocaleString()}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
  return (
    <Paper
      sx={{
        maxHeight: "200px",
        overflow: "scroll",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "10px",
          position: "sticky",
          top: 0,
          background: "white",
          zIndex: 2,
        }}
      >
        <Button
          sx={{ fontSize: "10px", textAlign: "center" }}
          startIcon={<SaveAltIcon sx={{ fontSize: "14px !important" }} />}
          onClick={downloadOdersAsCsv}
        >
          Export
        </Button>
      </Box>
      <TableContainer>
        <Table className={styles.toolTipTable}>
          <TableHead>
            <TableRow className={styles.toolTipRowHead}>
              <TableCell className={styles.tableHeadCell}>
                Missing Cost Products SKU
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              return (
                <TableRow key={product}>
                  <TableCell>{product}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  background: "#f2f1ed",
                }}
              >
                Total Product: {products.length}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const AdhocReplenishmentTable = ({ products, loading }) => {
  const gridRef = useRef();
  const totalRowDict = { quantity: 0, total_value: 0, adjusted_with_moq: 0 };
  const missingCostProducts = [];

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      minWidth: 250,
      flex: 1,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      minWidth: 350,
      flex: 2,
    },
    {
      field: "quantity",
      headerName: "QUANTITY",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "total_value",
      headerName: "TOTAL VALUE",
      minWidth: 150,
      flex: 1,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        if (params.data.id === "total") {
          return (
            <Tooltip
              sx={{ width: "100%", height: "100%" }}
              title={
                missingCostProducts.length !== 0 && (
                  <MissingCostProductTable products={missingCostProducts} />
                )
              }
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                {missingCostProducts.length !== 0 && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ErrorOutlineIcon fontSize="small" color="error" />
                  </Box>
                )}
                <Box>{"₹" + params.value}</Box>
              </Box>
            </Tooltip>
          );
        }
        if (params.value != 0) return "₹" + params.value;
        return (
          <Tooltip title="Missing Cost">
            <ErrorOutlineIcon fontSize="small" color="error" />
          </Tooltip>
        );
      },
    },
    {
      field: "adjusted_with_moq",
      headerName: "ADJUSTED WITH MOQ",
      minWidth: 200,
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];

  const rows = products.map((product, index) => {
    totalRowDict["quantity"] += product.quantity;
    totalRowDict["total_value"] += product.total_value;
    totalRowDict["adjusted_with_moq"] += product.adjusted_with_moq;
    if (product.total_value === 0) {
      missingCostProducts.push(product.sku);
    }
    return {
      id: index + 1,
      sku: product.sku,
      name: product.name,
      quantity: product.quantity,
      total_value: product.total_value,
      adjusted_with_moq: product.adjusted_with_moq,
    };
  });

  const totalRow = [
    {
      id: "total",
      sku: "Total",
      quantity: totalRowDict["quantity"],
      total_value: Math.round(totalRowDict["total_value"]),
      adjusted_with_moq: totalRowDict["adjusted_with_moq"],
    },
  ];

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Adhoc Replenishment(${
        brand.name
      }) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{ height: rows.length < 15 ? "" : "650px" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "650px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          domLayout={rows.length < 15 ? "autoHeight" : null}
          pinnedBottomRowData={rows.length && totalRow}
          rowHeight={35}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
          getRowStyle={(params) => {
            if (params.data.id === "total") {
              return {
                background: "#f2f1ed",
                fontSize: "15px",
                fontWeight: "bold",
              };
            }
            return null;
          }}
        />
      )}
    </Paper>
  );
};

export default AdhocReplenishmentTable;

import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";

const SalesForecastForm = ({
  brandName,
  setBrandName,
  fy,
  setFy,
  years,
  loading,
}) => {
  return (
    <form className={styles.form}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox}>
        <label className={styles.label}>Financial Year</label>
        <select
          value={fy}
          className={styles.select}
          onChange={(event) => setFy(event.target.value)}
          disabled={loading}
        >
          <option disabled>Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
};

export default SalesForecastForm;

import React from "react";
import styles from "./Styles.module.css";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import { DatePicker } from "rsuite";

const InventoryScoreForm = ({
  brandName,
  setBrandName,
  monthYear,
  setMonthYear,
}) => {
  return (
    <form className={styles.form}>
      <BrandSelectionForm brandName={brandName} setBrandName={setBrandName} />
      <div className={styles.inputBox}>
        <label className={styles.label}>Select Month</label>
      </div>
      <DatePicker
        className={styles.datePicker}
        format="MMM yyyy"
        placeholder="Select Month"
        size="sm"
        defaultValue={monthYear}
        onChange={(value) => setMonthYear(value)}
      />
    </form>
  );
};

export default InventoryScoreForm;

import React, { useContext, useEffect } from "react";
import styles from "./Styles.module.css";
import { fetchBrandConfig, updateOrCreate } from "../../Utils/helperFunctions";
import { toast } from "react-toastify";
import context from "../../Contexts/context";

const BrandConfigurationForm = ({
  brand,
  brandName,
  setBrandName,
  frequency,
  setFrequency,
  occurenceCount,
  setOccurenceCount,
  firstOcc,
  setFirstOcc,
  secondOcc,
  setSecondOcc,
  thirdOcc,
  setThirdOcc,
}) => {
  const { brands } = useContext(context);

  const updateOrCreateBrandDetails = async () => {
    if (occurenceCount == 1) {
      secondOcc = null;
      thirdOcc = null;
    } else if (occurenceCount == 2) {
      thirdOcc = null;
    }

    let postData = {
      brand: brandName,
      replenishment_frequency: frequency,
      replenishment_occurrence_count: parseInt(occurenceCount),
      occurrence_1_interval: parseInt(firstOcc),
      occurrence_2_interval: parseInt(secondOcc),
      occurrence_3_interval: parseInt(thirdOcc),
    };

    try {
      const response = await updateOrCreate(postData);
      if (response.data.message === "Configuration updated") {
        toast(response.data.message);
      }
    } catch (error) {
      toast.error("Error while updating the brand config!");
      console.log("Error while updating brand configs: ", error);
    }
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem(
      "brand",
      JSON.stringify({
        name: brandName,
        year: brand ? brand.year : new Date().getFullYear(),
      })
    );
    updateOrCreateBrandDetails();
  };

  const getBrandConfig = async (brandConfigUrl) => {
    try {
      const response = await fetchBrandConfig(brandConfigUrl);
      setFrequency(response.data.replenishment_frequency);
      setOccurenceCount(response.data.replenishment_occurrence_count);
      setFirstOcc(response.data.occurrence_1_interval);
      setSecondOcc(response.data.occurrence_2_interval);
      setThirdOcc(response.data.occurrence_3_interval);
    } catch (error) {
      toast.error("Error while get brand config");
      console.log("Error while get brand config: ", error);
    }
  };

  useEffect(() => {
    const config = brands.filter((brand) => brand.name == brandName);
    config.length && getBrandConfig(config[0].config);
  }, [brands, brandName]);

  return (
    <div className={styles.form}>
      <div className={styles.inputBox}>
        <label className={styles.label}>Brand</label>
        <select
          value={brandName}
          className={styles.selectInput}
          onChange={(e) => setBrandName(e.target.value)}
        >
          <option disabled>Select Brand</option>
          {brands.map((brand) => {
            return (
              <option value={brand.name} key={brand.name}>
                {brand.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className={styles.inputBox}>
        <label className={styles.label}>Ordering Frequency</label>
        <select
          value={frequency}
          className={styles.selectInput}
          onChange={(e) => setFrequency(e.target.value)}
        >
          <option value={"M"}>Monthly</option>
          <option value={"Q"}>Quarterly</option>
        </select>
        <select
          value={occurenceCount}
          className={styles.selectInput}
          onChange={(e) => setOccurenceCount(e.target.value)}
        >
          <option value={1}>Once</option>
          <option value={2}>Twice</option>
          <option value={3}>Thrice</option>
        </select>
        <span className={styles.middleText}>on</span>
        {occurenceCount == 1 ? (
          <input
            value={firstOcc}
            className={styles.inputOcc}
            style={{
              border: +firstOcc <= 0 ? "1px solid red" : "",
            }}
            type="number"
            min={1}
            onChange={(e) => setFirstOcc(e.target.value)}
          />
        ) : occurenceCount == 2 ? (
          <>
            <input
              value={firstOcc}
              className={styles.inputOcc}
              style={{
                border: +firstOcc <= 0 ? "1px solid red" : "",
              }}
              type="number"
              min={1}
              max={secondOcc && secondOcc - 1}
              onChange={(e) => setFirstOcc(e.target.value)}
            />
            <span className={styles.middleText}>,</span>
            <input
              value={secondOcc}
              className={styles.inputOcc}
              style={{
                border:
                  secondOcc && +secondOcc <= firstOcc ? "1px solid red" : "",
              }}
              type="number"
              min={+firstOcc + 1}
              max={thirdOcc && thirdOcc - 1}
              onChange={(e) => setSecondOcc(e.target.value)}
            />
          </>
        ) : (
          <>
            <input
              value={firstOcc}
              className={styles.inputOcc}
              style={{
                border: +firstOcc <= 0 ? "1px solid red" : "",
              }}
              type="number"
              min={1}
              max={secondOcc && secondOcc - 1}
              onChange={(e) => setFirstOcc(e.target.value)}
            />
            <span className={styles.middleText}>,</span>
            <input
              value={secondOcc}
              className={styles.inputOcc}
              style={{
                border:
                  secondOcc && +secondOcc <= firstOcc ? "1px solid red" : "",
              }}
              type="number"
              min={+firstOcc + 1}
              max={thirdOcc && thirdOcc - 1}
              onChange={(e) => setSecondOcc(e.target.value)}
            />
            <span className={styles.middleText}>,</span>
            <input
              value={thirdOcc}
              className={styles.inputOcc}
              style={{
                border:
                  thirdOcc && +thirdOcc <= secondOcc ? "1px solid red" : "",
              }}
              type="number"
              min={+secondOcc + 1}
              onChange={(e) => setThirdOcc(e.target.value)}
            />
          </>
        )}
      </div>
      <button className={styles.submitButton} onClick={onFormSubmit}>
        Create / Update
      </button>
    </div>
  );
};

export default BrandConfigurationForm;

import { useEffect, useState } from "react";
import PurchaseOrdersForm from "../../Components/PurchaseOrders/PurchaseOrdersForm";
import { fetchPurchaseOrders } from "../../Utils/helperFunctions";
import styles from "./PurchaseOrders.module.css";
import PurchaseOrdersTabel from "../../Components/PurchaseOrders/PurchaseOrdersTabel";
import { toast } from "react-toastify";

const PurchaseOrders = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [orders, setOrders] = useState([]);
  const [breachedOrders, setBreachedOrders] = useState([]);
  const [breachedFilter, setBreachedFilter] = useState(false);

  const getPurchaseOrders = async () => {
    setLoading(true);
    setOrders([]);
    const postData = { brand: brandName };
    try {
      const response = await fetchPurchaseOrders(postData);
      if (response.status === 200) {
        setOrders(response.data.purchase_orders);
        setBreachedOrders(
          response.data.purchase_orders.filter(
            (order) => new Date(order.delivery_date) < new Date()
          )
        );
        setLoading(false);
      }
    } catch (error) {
      toast("Error while get purchase orders!");
      console.log("Error while get purchase orders: ", error);
      setLoading(false);
    }
  };

  const handleBreachedFilter = () => {
    setBreachedFilter(!breachedFilter);
  };

  useEffect(() => {
    if (brandName) {
      getPurchaseOrders();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Purchase Orders</h2>
      <PurchaseOrdersForm
        brandName={brandName}
        setBrandName={setBrandName}
        breachedFilter={breachedFilter}
        handleBreachedFilter={handleBreachedFilter}
        loading={loading}
      />
      <PurchaseOrdersTabel
        orders={breachedFilter ? breachedOrders : orders}
        loading={loading}
      />
    </div>
  );
};

export default PurchaseOrders;

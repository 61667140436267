import React, { useEffect, useState } from "react";
import styles from "./ReplenishmentPlan.module.css";
import ReplenishmentForm from "../../Components/ReplenishmentPlan/ReplenishmentForm";
import { Box, Button, Typography } from "@mui/material";
import { fetchReplenishmentPlan } from "../../Utils/helperFunctions";
import ReplenishmentTable from "../../Components/ReplenishmentPlan/ReplenishmentTable";
import { toast } from "react-toastify";

const ReplenishmentPlan = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [products, setProducts] = useState([]);
  const [filterProductClasses, setFilterProductClasses] = useState([]);
  const [orderedOn, setOrderedOn] = useState("");
  const [getItBy, setGetItBy] = useState("");

  const getReplenishment = async () => {
    setLoading(true);
    setProducts([]);
    setFilterProductClasses([]);
    setOrderedOn("");
    setGetItBy("");
    const postData = { brand: brandName };
    try {
      const response = await fetchReplenishmentPlan(postData);
      setFilterProductClasses(response.data.filtered_product_classes);
      setProducts(response.data.products);
      setOrderedOn(response.data.replenishment_dates.first);
      setGetItBy(response.data.delivery_dates.first);
      setLoading(false);
    } catch (error) {
      toast("Error while get replenishments!");
      console.log("Error while get replenishment: ", error);
      setLoading(false);
    }
  };

  const removeFilter = async () => {
    const postData = { brand: brandName, include_muda: true };
    try {
      const response = await fetchReplenishmentPlan(postData);
      setFilterProductClasses([]);
      setProducts(response.data.products);
      setOrderedOn(response.data.replenishment_dates.first);
      setGetItBy(response.data.delivery_dates.first);
    } catch (error) {
      console.log("Error while removing filter: ", error);
    }
  };

  useEffect(() => {
    if (brandName) {
      getReplenishment();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Replenishment Plan</h2>
      <Box className={styles.dateBox}>
        <ReplenishmentForm
          brandName={brandName}
          setBrandName={setBrandName}
          loading={loading}
        />
        <Box className={styles.dates}>
          <b>Order on - </b>
          <span>{orderedOn}</span>
        </Box>
        <Box className={styles.dates}>
          <b>Get it by - </b>
          <span>{getItBy}</span>
        </Box>
      </Box>
      <Box className={styles.filterProducts}>
        <Box>
          <Typography>
            Products removed: {filterProductClasses.join(", ")}
          </Typography>
        </Box>
        <Box>
          <Button onClick={removeFilter}>Remove Filter</Button>
        </Box>
      </Box>
      <ReplenishmentTable
        products={products}
        removeFilter={removeFilter}
        filterProductClasses={filterProductClasses}
        loading={loading}
      />
    </div>
  );
};

export default ReplenishmentPlan;

import React, { useContext, useEffect } from "react";
import styles from "./Styles.module.css";
import context from "../../Contexts/context";

const BrandSelectionForm = ({ brandName, setBrandName, loading = false }) => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const { brands } = useContext(context);

  const onBrandChange = (event) => {
    setBrandName(event.target.value);
  };

  useEffect(() => {
    if (!brandName && brands.length) {
      setBrandName(brands[0].name);
    }
  }, [brands]);

  useEffect(() => {
    localStorage.setItem(
      "brand",
      JSON.stringify({
        name: brandName,
        year: brand ? brand.year : new Date().getFullYear(),
      })
    );
  }, [brandName]);

  return (
    <div className={styles.inputBox}>
      <label className={styles.label}>Brand</label>
      <select
        value={brandName}
        className={styles.input}
        onChange={(e) => onBrandChange(e)}
        disabled={loading}
      >
        <option disabled>Select Brand</option>
        {brands.map((brand) => {
          return (
            <option value={brand.name} key={brand.name}>
              {brand.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default BrandSelectionForm;

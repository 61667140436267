import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import ProductForm from "../../Components/Product/ProductForm";
import {
  fetchProducts,
  productBulkUpdate,
  productCsvUpload,
} from "../../Utils/helperFunctions";
import ProductTable from "../../Components/Product/ProductTable";
import { toast } from "react-toastify";
import ProductMoqForm from "../../Components/Product/ProductMoqForm";
import ConfirmationModal from "../../Components/Product/ConfirmationModal";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [updateProductLoading, setUpdateProductLoading] = useState(false);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [products, setProducts] = useState([]);
  const [productCsvFile, setProductCsvFile] = useState(null);
  const [productPostData, setProductPostData] = useState({ brand: brandName });
  const [openProductUpdateForm, setOpenProductUpdateForm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  // Function for open and close product update form
  const handleOpenProductUpdateForm = () => setOpenProductUpdateForm(true);
  const handleCloseProductUpdateForm = () =>
    !updateProductLoading && setOpenProductUpdateForm(false);

  // Function for open and close confirmation modal
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  const getProducts = async () => {
    setLoading(true);
    setProducts([]);
    try {
      const response = await fetchProducts({ brand: brandName });
      if (response.status === 200) {
        setProducts(response.data.products);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while get products!");
      console.log("Error while get proudcts: ", error);
      setLoading(false);
    }
  };

  const updateProducts = async (e) => {
    e.preventDefault();
    if (!productCsvFile) {
      toast.warn("Please upload a csv file");
      return;
    }
    setUpdateProductLoading(true);
    try {
      const postData = { brand: brandName, file: productCsvFile };
      const response = await productCsvUpload(postData);
      if (response.status === 200) {
        toast.success("Product updated successfully");
        getProducts();
        handleCloseProductUpdateForm();
      }
      setUpdateProductLoading(false);
    } catch (error) {
      console.log("Error while product bulk update: ", error);
      if (error.message === "Network Error") {
        toast.error(error.message);
      }
      if (error.response && error.response.status === 500) {
        toast.error(error.message);
      }
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
        toast.error(error.response.data.errors.file[0], {
          autoClose: 10000,
          className: styles.errorMessage,
        });
      }
      setUpdateProductLoading(false);
    }
  };

  const updateForAllProducts = async (e) => {
    e.preventDefault();
    setUpdateProductLoading(true);
    try {
      const response = await productBulkUpdate(productPostData);
      if (response.status === 200) {
        setProductPostData({ brand: brandName });
        getProducts();
        handleCloseProductUpdateForm();
        handleCloseConfirmationModal();
        toast.success("Proudct updated successfully");
      }
      setUpdateProductLoading(false);
    } catch (error) {
      handleCloseConfirmationModal();
      console.log(
        "Error while updating moq, lead time and safety stock: ",
        error
      );
      toast("Invalid data");
      setUpdateProductLoading(false);
    }
  };

  useEffect(() => {
    if (brandName) {
      getProducts();
    }
  }, [brandName]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Products</h2>
      <div className={styles.formBox}>
        <ProductForm
          brandName={brandName}
          setBrandName={setBrandName}
          loading={loading}
        />
        <Box className={styles.buttonBox}>
          <button
            className={styles.updateProductButton}
            onClick={() => navigate("/classifications")}
          >
            UPDATE CLASSIFICATIONS
          </button>
          <button
            className={styles.updateProductButton}
            onClick={handleOpenProductUpdateForm}
          >
            UPDATE PRODUCTS
          </button>
        </Box>
      </div>
      <ProductMoqForm
        openProductUpdateForm={openProductUpdateForm}
        handleCloseProductUpdateForm={handleCloseProductUpdateForm}
        productPostData={productPostData}
        setProductPostData={setProductPostData}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        updateProducts={updateProducts}
        setProductCsvFile={setProductCsvFile}
        updateProductLoading={updateProductLoading}
      />
      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        updateForAllProducts={updateForAllProducts}
      />
      <ProductTable products={products} loading={loading} />
    </div>
  );
};

export default Product;

import React, { useEffect, useState } from "react";
import styles from "./SalesForecast.module.css";
import { fetchSalesPrediction } from "../../Utils/helperFunctions";
import SalesForecastForm from "../../Components/SalesForecast/SalesForecastForm";
import { Alert, Box, Paper } from "@mui/material";
import SalesForecastTable from "../../Components/SalesForecast/SalesForecastTable";
import ConfidenceScoreTable from "../../Components/SalesForecast/ConfidenceScoreTable";
import { toast } from "react-toastify";

const SalesForecast = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(false);
  const [revenues, setRevenues] = useState([]);
  const currentYear = new Date().getFullYear();
  const currnetMonth = new Date().getMonth();
  const currentFinancialYear = brand
    ? currnetMonth > 2
      ? brand.year
      : brand.year - 1
    : currnetMonth > 2
    ? currentYear
    : currentYear - 1;
  const [channels, setChannels] = useState([]);
  const [salesPrediction, setSalesPrediction] = useState({});
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const [fy, setFy] = useState(
    currnetMonth > 2
      ? currentFinancialYear + `-${currentFinancialYear + 1}`
      : currentFinancialYear - 1 + `${currentFinancialYear}`
  );

  const years =
    currnetMonth > 2
      ? [
          currentYear + 2 + `-${currentYear + 3}`,
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
        ]
      : [
          currentYear + 1 + `-${currentYear + 2}`,
          currentYear + `-${currentYear + 1}`,
          currentYear - 1 + `-${currentYear}`,
          currentYear - 2 + `-${currentYear - 1}`,
          currentYear - 3 + `-${currentYear - 2}`,
        ];

  const getSalesPrediction = async () => {
    setSalesPrediction({});
    setChannels([]);
    setLoading(true);
    const postData = { brand: brandName, financial_year: fy };
    try {
      const response = await fetchSalesPrediction(postData);
      if (response.status === 200) {
        setSalesPrediction(response.data);
        setChannels(Object.keys(response.data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error while get sales prediction!");
      console.log("Error while get sales prediction!", error);
    }
  };

  useEffect(() => {
    if (brandName && fy) {
      localStorage.setItem(
        "brand",
        JSON.stringify({
          name: brandName,
          year: +fy.split("-")[0],
        })
      );
      getSalesPrediction();
    }
  }, [brandName, fy]);

  return (
    <Box className={styles.container}>
      <h2 className={styles.heading}>Sales Forecast Input</h2>
      <SalesForecastForm
        brandName={brandName}
        setBrandName={setBrandName}
        fy={fy}
        setFy={setFy}
        years={years}
        loading={loading}
      />
      <Alert severity="warning">
        <b style={{ fontSize: "14px" }}>
          Please note: When entering LE, refrain from factoring in new products.
          Focus solely on existing products for accurate replenishment
          calculations.
        </b>
      </Alert>
      <SalesForecastTable
        brandName={brandName}
        fy={fy}
        channels={channels}
        salesPrediction={salesPrediction}
        revenues={revenues}
        setRevenues={setRevenues}
        getSalesPrediction={getSalesPrediction}
        loading={loading}
      />
      <ConfidenceScoreTable
        salesPrediction={salesPrediction}
        loading={loading}
      />
    </Box>
  );
};

export default SalesForecast;

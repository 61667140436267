import { CircularProgress, Divider, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./Styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import ProductUpdateForm from "./ProductUpdateForm";
import { toast } from "react-toastify";

const ProductMoqForm = ({
  openProductUpdateForm,
  handleCloseProductUpdateForm,
  productPostData,
  setProductPostData,
  handleOpenConfirmationModal,
  updateProducts,
  setProductCsvFile,
  updateProductLoading,
}) => {
  return (
    <Box>
      <Modal
        open={openProductUpdateForm}
        onClose={handleCloseProductUpdateForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalForm}>
          <Typography
            variant="h6"
            align="center"
            sx={{ marginBottom: 4, fontWeight: "bold" }}
          >
            PRODUCT UPDATE
          </Typography>
          <Box className={styles.prouductUpdateFormBox}>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                productPostData.lead_time ||
                productPostData.safety_stock ||
                productPostData.moq
                  ? handleOpenConfirmationModal()
                  : toast.info("Nothing to update!");
              }}
              className={styles.globalProductUpdateForm}
            >
              <CloseIcon
                className={styles.closeModalIcon}
                onClick={handleCloseProductUpdateForm}
              />
              <Typography className={styles.subFormHeading}>
                UPDATE ALL PRODUCTS
              </Typography>
              <input
                className={styles.modalInput}
                placeholder="Lead Time (days)"
                type="number"
                min={0}
                onChange={(e) => {
                  if (e.target.value) {
                    setProductPostData({
                      ...productPostData,
                      lead_time: e.target.value,
                    });
                  } else {
                    const updatedProductPostData = { ...productPostData };
                    delete updatedProductPostData.lead_time;
                    setProductPostData(updatedProductPostData);
                  }
                }}
              />
              <input
                className={styles.modalInput}
                placeholder="Safety Stock (days)"
                type="number"
                min={0}
                onChange={(e) => {
                  if (e.target.value) {
                    setProductPostData({
                      ...productPostData,
                      safety_stock: e.target.value,
                    });
                  } else {
                    const updatedProductPostData = { ...productPostData };
                    delete updatedProductPostData.safety_stock;
                    setProductPostData(updatedProductPostData);
                  }
                }}
              />
              <input
                className={styles.modalInput}
                placeholder="MOQ"
                type="number"
                min={0}
                onChange={(e) => {
                  if (e.target.value) {
                    setProductPostData({
                      ...productPostData,
                      moq: e.target.value,
                    });
                  } else {
                    const updatedProductPostData = { ...productPostData };
                    delete updatedProductPostData.moq;
                    setProductPostData(updatedProductPostData);
                  }
                }}
              />
              <input
                type="submit"
                value={"UPDATE"}
                className={styles.modalSubmitButton}
              />
            </form>
            <div className={styles.deviderBox}>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
              <Typography variant="h6">OR</Typography>
              <Divider orientation="vertical" variant="fullWidth" flexItem />
            </div>
            <ProductUpdateForm
              updateProducts={updateProducts}
              setProductCsvFile={setProductCsvFile}
            />
          </Box>
          {updateProductLoading && (
            <Box
              className={styles.loadingOverlay}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductMoqForm;

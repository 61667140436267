import { Box } from "@mui/system";
import React, { useCallback, useMemo, useRef } from "react";
import styles from "./Styles.module.css";
import { Button, Paper, TextField } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const ProductTable = ({ products, loading }) => {
  const gridRef = useRef();

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
      pinned: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "cost",
      headerName: "₹ Cost",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        if (params.value) return "₹" + params.value;
      },
    },
    {
      field: "mrp",
      headerName: "₹ MRP",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        if (params.value) return "₹" + params.value;
      },
    },
    {
      field: "latest_selling_price",
      headerName: "₹ Latest Selling Price",
      flex: 1,
      minWidth: 180,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => {
        if (params.value) return "₹" + params.value;
      },
    },
    {
      field: "minimum_order_quantity",
      headerName: "MOQ",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "classification__title",
      headerName: "Classification",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "lead_time",
      headerName: "Lead Time",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "safety_stock",
      headerName: "Safety Stock",
      flex: 1,
      minWidth: 150,
      cellStyle: { textAlign: "center" },
    },
  ];

  const rows = products.map((product, index) => {
    return {
      id: index + 1,
      sku: product.sku,
      name: product.name,
      cost: product.cost,
      mrp: product.mrp,
      latest_selling_price: product.latest_selling_price,
      minimum_order_quantity: product.minimum_order_quantity,
      classification__title: product.classification__title,
      lead_time: product.lead_time,
      safety_stock: product.safety_stock,
    };
  });

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onBtnExport = useCallback(() => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    const params = {
      fileName: `Products(${brand.name}) ${new Date().toLocaleString()}`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [100, 200, 500];
  }, []);

  return (
    <Paper
      sx={{ height: rows.length < 17 ? "" : "700px", margin: "2em 0" }}
      className={`ag-theme-quartz ${styles.tableContainer}`}
    >
      <Box className={styles.customTableHeader}>
        <Button
          className="exportButton"
          onClick={onBtnExport}
          startIcon={<SaveAltIcon />}
          disabled={loading}
        >
          Export
        </Button>
        <TextField
          type="text"
          size="small"
          label="Search"
          id="filter-text-box"
          className={styles.searchField}
          onInput={onFilterTextBoxChanged}
          disabled={loading}
        />
      </Box>
      {loading ? (
        <Box sx={{ height: "700px" }} className="ag-theme-quartz">
          <AgGridReact columnDefs={columns} rowData={null} />
        </Box>
      ) : (
        <AgGridReact
          domLayout={rows.length < 17 ? "autoHeight" : ""}
          ref={gridRef}
          columnDefs={columns}
          rowData={rows}
          rowHeight={35}
          pagination={true}
          paginationPageSize={100}
          paginationPageSizeSelector={paginationPageSizeSelector}
          enableCellTextSelection={true}
          suppressMovableColumns={true}
        />
      )}
    </Paper>
  );
};

export default ProductTable;

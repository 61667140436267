import React, { useEffect, useRef, useState } from "react";
import styles from "./OutOfStockLoss.module.css";
import { Button, Box, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  fetchClassification,
  fetchOutOfStockLosses,
} from "../../Utils/helperFunctions";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import OutOfStockForm from "../../Components/OutOfStockLoss/OutOfStockForm";
import OutOfStockGraph from "../../Components/OutOfStockLoss/OutOfStockGraph";
import ProductStockLoss from "../../Components/OutOfStockLoss/ProductStockLoss";
import { toast } from "react-toastify";

const OutOfStockLoss = () => {
  const brand = JSON.parse(localStorage.getItem("brand"));
  const [loading, setLoading] = useState(true);
  const [brandName, setBrandName] = useState(brand ? brand.name : "");
  const isBrandChange = useRef(false);
  const currentDate = new Date();
  let startDate = new Date();
  startDate.setDate(currentDate.getDate() - 15);
  startDate = startDate.toISOString().split("T")[0];
  let endDate = new Date();
  endDate.setDate(currentDate.getDate() + 15);
  endDate = endDate.toISOString().split("T")[0];
  const [dateRange, setDateRange] = useState(`${startDate}~${endDate}`);
  const [stockLossesData, setStockLossesData] = useState([]);
  const [productStockLossData, setProductStockLossData] = useState([]);
  const [productStockLoss, setProductStockLoss] = useState([]);
  const [totalStockLoss, setTotalStockLoss] = useState("");
  const [classifications, setClassifications] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [dateOnGraph, setDateOnGraph] = useState(dateRange);
  const [forecast, setForecast] = useState(false);

  const getStockLoss = async (brandChange = false) => {
    setLoading(true);
    setStockLossesData([]);
    setTotalStockLoss("");
    let [firstDate, lastDate] = dateRange.split("~");
    const postData = {
      brand: brandName,
      date_start: firstDate,
      date_end: lastDate,
      classification: brandChange
        ? []
        : selectedClassifications.map((classification) => classification.value),
    };
    try {
      const response = await fetchOutOfStockLosses(postData);

      if (response.status === 200) {
        setStockLossesData(response.data.stock_losses);
        setTotalStockLoss(response.data.total_stock_loss);
        setProductStockLossData(response.data.products);
        setLoading(false);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.non_field_errors
      ) {
        toast.error(error.response.data.errors.non_field_errors[0]);
      } else {
        toast("Error while get stock loss!");
      }
      console.log("Error while get stock loass: ", error);
      setLoading(false);
    }
    isBrandChange.current = false;
  };

  const getClassification = async () => {
    const postData = { brand: brandName };
    try {
      const response = await fetchClassification(postData);
      setClassifications(
        response.data.classifications.map((value) => {
          return { value: value.title, label: value.title };
        })
      );
    } catch (error) {
      console.log("Error while get classification: ", error);
    }
  };

  const handleSelectClassification = (selectedValue) => {
    setSelectedClassifications(selectedValue);
  };

  const resetBarGraph = () => {
    setProductStockLoss(productStockLossData);
    setDateOnGraph(dateRange);
    setForecast(false);
  };

  const convertArrayToCSV = (stockLoss) => {
    // Extract keys (header row)
    const keys = Object.keys(stockLoss[0]);
    // Generate CSV content
    const csvContent = stockLoss.map((row) => {
      return keys
        .map((key) => {
          let cell = row[key];
          if (typeof cell === "string") {
            // Escape double quotes by doubling them
            cell = cell.replace(/"/g, '""');
            // Enclose field in double quotes if it contains commas or double quotes
            if (cell.includes(",") || cell.includes('"')) {
              cell = `"${cell}"`;
            }
          }
          return cell;
        })
        .join(",");
    });

    // Add header row
    csvContent.unshift(keys.join(","));

    // Join all rows into a single string with newline characters
    return csvContent.join("\n");
  };

  const downloadOutOfStockzlossCsv = () => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    if (stockLossesData.length === 0) {
      toast.info("Empty Data!");
      return;
    }
    const csv = convertArrayToCSV(
      stockLossesData.map((stockLoss) => {
        return {
          date: stockLoss.date,
          out_of_stock_loss: Math.round(stockLoss.out_of_stock_loss),
        };
      })
    );
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Out of Stock Loss(${
      brand.name
    }) Date Range(${dateRange}) ${new Date().toLocaleString()}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const downloadProductOutOfStockzlossCsv = () => {
    const brand = JSON.parse(localStorage.getItem("brand"));
    if (productStockLoss.length === 0) {
      toast.info("Empty Data!");
      return;
    }
    const csv = convertArrayToCSV(
      productStockLoss.map((stockLoss) => {
        return {
          sku: stockLoss.sku,
          out_of_stock_loss: Math.round(stockLoss.out_of_stock_loss),
          name: stockLoss.name,
        };
      })
    );
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Out of Stock Loss(${
      brand.name
    }) Date Range(${dateOnGraph}) ${new Date().toLocaleString()}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    setProductStockLoss(productStockLossData);
  }, [productStockLossData]);

  useEffect(() => {
    setProductStockLoss([]);
    setDateOnGraph(dateRange);
    setForecast(false);
  }, [dateRange, selectedClassifications]);

  useEffect(() => {
    isBrandChange.current = true;
    setSelectedClassifications([]);
    setClassifications([]);
    setProductStockLoss([]);
    setDateOnGraph(dateRange);
    setForecast(false);
    if (brandName) {
      getClassification();
    }
  }, [brandName]);

  useEffect(() => {
    if (brandName && dateRange) {
      getStockLoss(isBrandChange.current);
    }
  }, [brandName, dateRange, selectedClassifications]);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Out of Stock Loss</h2>
      <OutOfStockForm
        brandName={brandName}
        setBrandName={setBrandName}
        dateRange={dateRange}
        setDateRange={setDateRange}
        classifications={classifications}
        selectedClassifications={selectedClassifications}
        handleSelectClassification={handleSelectClassification}
        getStockLoss={getStockLoss}
        loading={loading}
      />
      <Box className={styles.totalLoss}>
        <span>
          Out of stock loss -{" "}
          {loading ? "" : "₹" + Math.round(totalStockLoss).toLocaleString()}
        </span>
      </Box>
      <Box className={styles.lineGraph}>
        <Button
          className={styles.exportButton}
          onClick={downloadOutOfStockzlossCsv}
          disabled={loading}
        >
          <SaveAltSharpIcon className={styles.downloadIcon} />
          <Typography className={styles.buttonText}> Export</Typography>
        </Button>
        <Typography className={styles.graphHeading}>
          OUT OF STOCK LOSS
        </Typography>
        <OutOfStockGraph
          stockLossesData={stockLossesData}
          setProductStockLoss={setProductStockLoss}
          dateRange={dateRange}
          setDateOnGraph={setDateOnGraph}
          setForecast={setForecast}
          loading={loading}
        />
      </Box>
      <Box className={styles.barGraph}>
        <Button
          className={styles.exportButton}
          onClick={downloadProductOutOfStockzlossCsv}
          disabled={loading}
        >
          <SaveAltSharpIcon className={styles.downloadIcon} />
          <Typography className={styles.buttonText}> Export</Typography>
        </Button>
        <Button
          className={styles.resetButton}
          onClick={resetBarGraph}
          disabled={loading}
          title="Reset"
        >
          <RefreshIcon className={styles.resetIcon} />
          <Typography className={styles.buttonText}>Reset</Typography>
        </Button>
        <Typography className={styles.graphHeading}>PRODUCT</Typography>
        <ProductStockLoss
          productStockLoss={productStockLoss}
          dateOnGraph={dateOnGraph}
          forecast={forecast}
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default OutOfStockLoss;

import styles from "./Styles.module.css";
import Select from "react-select";
import BrandSelectionForm from "../../BrandSelectionForm/BrandSelectionForm";

const PlannedVsActualForm = ({
  brandName,
  setBrandName,
  financialYears,
  fy,
  setFy,
  classifications,
  selectedClassifications,
  handleSelectClassification,
  loading,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "25px",
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "25px",
      margin: "auto",
    }),
  };

  return (
    <form className={styles.form}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <label className={styles.label}>Financial Year</label>
      <select
        value={fy}
        className={styles.select}
        onChange={(event) => setFy(event.target.value)}
        disabled={loading}
      >
        <option disabled>Select Year</option>
        {financialYears.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <label className={styles.label}>Classification</label>
      <Select
        styles={customStyles}
        placeholder="Select Classifications"
        closeMenuOnSelect={false}
        isMulti
        options={classifications}
        value={selectedClassifications}
        onChange={handleSelectClassification}
        className={styles.selectInput}
        isDisabled={loading}
      />
    </form>
  );
};

export default PlannedVsActualForm;

import "./App.css";
import Main from "./Pages/main";
import MenuBar from "./Components/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { fetchBrands, isAuthenticated } from "./Utils/helperFunctions";
import context from "./Contexts/context";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [token, setToken] = useState(
    localStorage.getItem("token") ? true : false
  );

  const getBrands = async () => {
    try {
      const response = await fetchBrands();
      if (response.status === 200) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log("Error while get brands: ", error);
    }
  };

  const checkAuthentication = async () => {
    try {
      const response = await isAuthenticated();
      localStorage.setItem("token", response.data.authenticated);
      setToken(response.data.authenticated);
    } catch (error) {
      localStorage.setItem("token", false);
      setToken(false);
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkAuthentication();
    if (location.pathname === "/sign-in" && token) {
      navigate("/");
    }
  }, [location.pathname]);

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <context.Provider value={{ brands, setToken, setBrands }}>
      <div className="App">
        {token && <MenuBar />}
        <Main token={token} />
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="dark"
        />
      </div>
    </context.Provider>
  );
}

export default App;

import React from "react";
import styles from "./Styles.module.css";
import {
  Card,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";

const ScoreScaleBox = ({ score }) => {
  return (
    <Paper>
      <TableContainer>
        <Table className={styles.toolTipTable}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                Score-chart based on deviation %
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Green */}
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 10 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={3}
              className={
                score == 10 || score == 9 || score == 8 ? styles.boldCol : ""
              }
            >
              {"0-14%"}
            </TableCell>
            <TableCell>{"<3%"}</TableCell>
            <TableCell>{"10"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 9 ? styles.boldRow : ""}
          >
            <TableCell>{"<8%"}</TableCell>
            <TableCell>{"9"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 8 ? styles.boldRow : ""}
          >
            <TableCell>{"<14%"}</TableCell>
            <TableCell>{"8"}</TableCell>
          </TableRow>
          {/* Yellow */}
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 7 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={5}
              className={
                score == 7 ||
                score == 6 ||
                score == 5 ||
                score == 4 ||
                score == 3
                  ? styles.boldCol
                  : ""
              }
            >
              {"14-51%"}
            </TableCell>
            <TableCell>{"<20%"}</TableCell>
            <TableCell>{"7"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 6 ? styles.boldRow : ""}
          >
            <TableCell>{"<26%"}</TableCell>
            <TableCell>{"6"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 5 ? styles.boldRow : ""}
          >
            <TableCell>{"<33%"}</TableCell>
            <TableCell>{"5"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 4 ? styles.boldRow : ""}
          >
            <TableCell>{"<41%"}</TableCell>
            <TableCell>{"4"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 3 ? styles.boldRow : ""}
          >
            <TableCell>{"<51%"}</TableCell>
            <TableCell>{"3"}</TableCell>
          </TableRow>
          {/* Red */}
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 2 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={3}
              className={
                score == 2 || score == 1 || score == 0 ? styles.boldCol : ""
              }
            >
              {"51-100%"}
            </TableCell>
            <TableCell>{"<62%"}</TableCell>
            <TableCell>{"2"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 1 ? styles.boldRow : ""}
          >
            <TableCell>{"<78%"}</TableCell>
            <TableCell>{"1"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 0 ? styles.boldRow : ""}
          >
            <TableCell>{"100-78%"}</TableCell>
            <TableCell>{"0"}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const MudaScoreScaleBox = ({ score }) => {
  return (
    <Paper>
      <TableContainer>
        <Table className={styles.toolTipTable}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                Score-chart based on muda %
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Green */}
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 10 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={3}
              className={
                score == 10 || score == 9 || score == 8 ? styles.boldCol : ""
              }
            >
              {"0-5%"}
            </TableCell>
            <TableCell>{"<1%"}</TableCell>
            <TableCell>{"10"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 9 ? styles.boldRow : ""}
          >
            <TableCell>{"<3%"}</TableCell>
            <TableCell>{"9"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#BFF6C3" }}
            className={score == 8 ? styles.boldRow : ""}
          >
            <TableCell>{"<5%"}</TableCell>
            <TableCell>{"8"}</TableCell>
          </TableRow>
          {/* Yellow */}
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 7 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={5}
              className={
                score == 7 ||
                score == 6 ||
                score == 5 ||
                score == 4 ||
                score == 3
                  ? styles.boldCol
                  : ""
              }
            >
              {"5-18%"}
            </TableCell>
            <TableCell>{"<6%"}</TableCell>
            <TableCell>{"7"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 6 ? styles.boldRow : ""}
          >
            <TableCell>{"<9%"}</TableCell>
            <TableCell>{"6"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 5 ? styles.boldRow : ""}
          >
            <TableCell>{"<11%"}</TableCell>
            <TableCell>{"5"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 4 ? styles.boldRow : ""}
          >
            <TableCell>{"<14%"}</TableCell>
            <TableCell>{"4"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FDFFAB" }}
            className={score == 3 ? styles.boldRow : ""}
          >
            <TableCell>{"<18%"}</TableCell>
            <TableCell>{"3"}</TableCell>
          </TableRow>
          {/* Red */}
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 2 ? styles.boldRow : ""}
          >
            <TableCell
              rowSpan={3}
              className={
                score == 2 || score == 1 || score == 0 ? styles.boldCol : ""
              }
            >
              {"51-100%"}
            </TableCell>
            <TableCell>{"<24%"}</TableCell>
            <TableCell>{"2"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 1 ? styles.boldRow : ""}
          >
            <TableCell>{"<35%"}</TableCell>
            <TableCell>{"1"}</TableCell>
          </TableRow>
          <TableRow
            sx={{ background: "#FF9B9B" }}
            className={score == 0 ? styles.boldRow : ""}
          >
            <TableCell>{"100-35%"}</TableCell>
            <TableCell>{"0"}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const InventoryScoreTable = ({ scores, monthYear, loading }) => {
  const inventoryScoreCol = [
    {
      dataKey: "sub_category",
      label: "Classification",
      width: 80,
    },
    {
      dataKey: "norm_value",
      label: "Norm Units",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "actual_value",
      label: "Actual Units",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "score",
      label: "Score",
      width: 80,
      numeric: true,
    },
  ];

  const drrScoreCol = [
    {
      dataKey: "sub_category",
      label: "Classification",
      width: 80,
    },
    {
      dataKey: "norm_value",
      label: "Norm DRR",
      width: 60,
      numeric: true,
    },
    {
      dataKey: "actual_value",
      label: "Actual DRR (Last 1 Month)",
      width: 110,
      numeric: true,
    },
    {
      dataKey: "actual_value_extra",
      label: "Actual DRR (Last 3 Months)",
      width: 110,
      numeric: true,
    },
    {
      dataKey: "score",
      label: "Score",
      width: 80,
      numeric: true,
    },
  ];

  const dohScoreCol = [
    {
      dataKey: "sub_category",
      label: "Classification",
      width: 80,
    },
    {
      dataKey: "norm_value",
      label: "Norm DOH",
      width: 60,
      numeric: true,
    },
    {
      dataKey: "actual_value",
      label: "Actual DOH (Last 1 month DRR)",
      width: 110,
      numeric: true,
    },
    {
      dataKey: "actual_value_extra",
      label: "Actual DOH (Last 3 Months DRR)",
      width: 110,
      numeric: true,
    },
    {
      dataKey: "score",
      label: "Score",
      width: 80,
      numeric: true,
    },
  ];

  const mudaScoreCol = [
    {
      dataKey: "sub_category",
      label: "Classification",
      width: 80,
    },
    {
      dataKey: "norm_value",
      label: "Norm Units",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "actual_value",
      label: "Actual Units",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "score",
      label: "Score",
      width: 80,
      numeric: true,
    },
  ];

  const dohCountScoreCol = [
    {
      dataKey: "sub_category",
      label: "Classification",
      width: 80,
    },
    {
      dataKey: "norm_value",
      label: "Norm Count",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "actual_value",
      label: "Actual Count",
      width: 150,
      numeric: true,
    },
    {
      dataKey: "score",
      label: "Score",
      width: 80,
      numeric: true,
    },
  ];

  return (
    <Paper sx={{ marginTop: "2em" }}>
      <Box
        className={styles.scoreCard}
        sx={{
          backgroundColor:
            scores.score >= 8
              ? "#BFF6C3"
              : scores.score >= 3
              ? "#FDFFAB"
              : scores.score >= 0
              ? "#FF9B9B"
              : "black",
          color: scores.score ? "black" : "white",
        }}
      >
        Inventory Scorecard{" "}
        {new Date(monthYear).toLocaleString("default", {
          month: "long",
        })}{" "}
        Month <Card className={styles.score}>{scores.score} / 10</Card>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "700px", minHeight: "300px", position: "relative" }}
      >
        {!loading && scores.calculation_date ? (
          <Box className={styles.table}>
            {/* Inventory Score */}
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell colSpan={4} className={styles.tableSubHead}>
                    {scores.inventory_score.title}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableHeadRow}>
                  {inventoryScoreCol.map((column) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={column.dataKey}
                      variant="head"
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.inventory_score.scores.map((inventory_score, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                        inventory_score.overall_field ? styles.overAllRow : ""
                      }
                    >
                      <TableCell>{inventory_score.sub_category}</TableCell>
                      <TableCell>{inventory_score.norm_value}</TableCell>
                      <TableCell>{inventory_score.actual_value}</TableCell>
                      <Tooltip
                        title={<ScoreScaleBox score={inventory_score.score} />}
                        arrow
                      >
                        <TableCell
                          sx={{
                            backgroundColor:
                              inventory_score.score >= 8
                                ? "#BFF6C3"
                                : inventory_score.score >= 3
                                ? "#FDFFAB"
                                : inventory_score.score >= 0
                                ? "#FF9B9B"
                                : "white",
                          }}
                        >
                          {inventory_score.score}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* DRR Score */}
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell colSpan={5} className={styles.tableSubHead}>
                    {scores.drr_score.title}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableHeadRow}>
                  {drrScoreCol.map((column) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={column.dataKey}
                      variant="head"
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.drr_score.scores.map((drr_score, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                        drr_score.overall_field ? styles.overAllRow : ""
                      }
                    >
                      <TableCell>{drr_score.sub_category}</TableCell>
                      <TableCell>{drr_score.norm_value}</TableCell>
                      <TableCell>{drr_score.actual_value}</TableCell>
                      <TableCell>{drr_score.actual_value_extra}</TableCell>
                      <Tooltip
                        title={<ScoreScaleBox score={drr_score.score} />}
                        arrow
                      >
                        <TableCell
                          sx={{
                            backgroundColor:
                              drr_score.score >= 8
                                ? "#BFF6C3"
                                : drr_score.score >= 3
                                ? "#FDFFAB"
                                : drr_score.score >= 0
                                ? "#FF9B9B"
                                : "white",
                          }}
                        >
                          {drr_score.score}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* DOH Score */}
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell colSpan={5} className={styles.tableSubHead}>
                    {scores.doh_score.title}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableHeadRow}>
                  {dohScoreCol.map((column) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={column.dataKey}
                      variant="head"
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.doh_score.scores.map((doh_score, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                        doh_score.overall_field ? styles.overAllRow : ""
                      }
                    >
                      <TableCell>{doh_score.sub_category}</TableCell>
                      <TableCell>{doh_score.norm_value}</TableCell>
                      <TableCell>{doh_score.actual_value}</TableCell>
                      <TableCell>{doh_score.actual_value_extra}</TableCell>
                      <Tooltip
                        title={<ScoreScaleBox score={doh_score.score} />}
                        arrow
                      >
                        <TableCell
                          sx={{
                            backgroundColor:
                              doh_score.score >= 8
                                ? "#BFF6C3"
                                : doh_score.score >= 3
                                ? "#FDFFAB"
                                : doh_score.score >= 0
                                ? "#FF9B9B"
                                : "white",
                          }}
                        >
                          {doh_score.score}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* MUDA Score */}
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell colSpan={5} className={styles.tableSubHead}>
                    {scores.muda_score.title}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableHeadRow}>
                  {mudaScoreCol.map((column) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={column.dataKey}
                      variant="head"
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.muda_score.scores.map((muda_score, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={
                        muda_score.overall_field ? styles.overAllRow : ""
                      }
                    >
                      <TableCell>{muda_score.sub_category}</TableCell>
                      <TableCell>{muda_score.norm_value}</TableCell>
                      <TableCell>{muda_score.actual_value}</TableCell>
                      <Tooltip
                        title={<MudaScoreScaleBox score={muda_score.score} />}
                        arrow
                      >
                        <TableCell
                          sx={{
                            backgroundColor:
                              muda_score.score >= 8
                                ? "#BFF6C3"
                                : muda_score.score >= 3
                                ? "#FDFFAB"
                                : muda_score.score >= 0
                                ? "#FF9B9B"
                                : "white",
                          }}
                        >
                          {muda_score.score}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/* DOH Count Score */}
            <Table>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell colSpan={5} className={styles.tableSubHead}>
                    {scores.doh_count_score.title}
                  </TableCell>
                </TableRow>
                <TableRow className={styles.tableHeadRow}>
                  {dohCountScoreCol.map((column) => (
                    <TableCell
                      className={styles.tableHeadCell}
                      key={column.dataKey}
                      variant="head"
                      style={{ width: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {scores.doh_count_score.scores.map((doh_count_score, index) => {
                  return (
                    <TableRow
                      index={index}
                      className={
                        doh_count_score.overall_field ? styles.overAllRow : ""
                      }
                    >
                      <TableCell>{doh_count_score.sub_category}</TableCell>
                      <TableCell>{doh_count_score.norm_value}</TableCell>
                      <TableCell>{doh_count_score.actual_value}</TableCell>
                      <Tooltip
                        title={<ScoreScaleBox score={doh_count_score.score} />}
                        arrow
                      >
                        <TableCell
                          sx={{
                            backgroundColor:
                              doh_count_score.score >= 8
                                ? "#BFF6C3"
                                : doh_count_score.score >= 3
                                ? "#FDFFAB"
                                : doh_count_score.score >= 0
                                ? "#FF9B9B"
                                : "white",
                          }}
                        >
                          {doh_count_score.score}
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        ) : loading ? (
          <CircularProgress
            sx={{ position: "absolute", left: "50%", top: "50%" }}
          />
        ) : (
          <Box sx={{ position: "absolute", left: "49%", top: "50%" }}>
            No Data
          </Box>
        )}
      </TableContainer>
    </Paper>
  );
};

export default InventoryScoreTable;

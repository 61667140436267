import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import styles from "./Styles.module.css";

const ReplenishmentForm = ({ brandName, setBrandName, loading }) => {
  return (
    <BrandSelectionForm
      brandName={brandName}
      setBrandName={setBrandName}
      loading={loading}
    />
  );
};

export default ReplenishmentForm;

import { Checkbox, FormControlLabel } from "@mui/material";
import BrandSelectionForm from "../BrandSelectionForm/BrandSelectionForm";
import Select from "react-select";
import styles from "./Styles.module.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "25px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "25px",
    margin: "auto",
  }),
};

const DailyInventoryForm = ({
  brandName,
  setBrandName,
  classifications,
  selectedClassifications,
  handleSelectClassification,
  showB2B,
  showB2C,
  handleB2BToggle,
  handleB2CToggle,
  loading,
}) => {
  return (
    <div className={styles.formBox}>
      <BrandSelectionForm
        brandName={brandName}
        setBrandName={setBrandName}
        loading={loading}
      />
      <div className={styles.inputBox} style={{ marginLeft: "2em" }}>
        <label className={styles.label}>Classification</label>
        <Select
          styles={customStyles}
          placeholder="Select Classifications"
          closeMenuOnSelect={false}
          isMulti
          options={classifications}
          value={selectedClassifications}
          onChange={handleSelectClassification}
          className={styles.selectInput}
          isDisabled={loading}
        />
      </div>
      <FormControlLabel
        className={styles.checkbox}
        label="B2C"
        labelPlacement="start"
        control={<Checkbox checked={showB2C} onChange={handleB2CToggle} />}
        disabled={loading}
      />
      <FormControlLabel
        className={styles.checkbox}
        label="B2B"
        labelPlacement="start"
        control={<Checkbox checked={showB2B} onChange={handleB2BToggle} />}
        disabled={loading}
      />
    </div>
  );
};

export default DailyInventoryForm;
